import {CostCardTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CostCardTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CostCardTypeCd ) {
      this._values.push(CostCardTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CostCardTypeCd {

    for ( const obj in CostCardTypeCd ) {
      if (CostCardTypeCd[obj] === value){
        return CostCardTypeCd[obj] as CostCardTypeCd;
      }
    }
  }
}

const CostCardTypeCdHelper = new CostCardTypeCdHelperEnumHelperClass();
export default CostCardTypeCdHelper;
