
export enum InspectionCorrectionIneligibleReasonCd {
	BILL_CLASS_INVALID = 'BillClassInvalid',
	EXISTING_ELS_ACCESSORIAL = 'ExistingElsAccessorial',
	EXISTING_ELS_CORRECTION = 'ExistingElsCorrection',
	EXISTING_INSPECTION_CORRECTION = 'ExistingInspectionCorrection',
	EXISTING_XCC_ACCESSORIAL = 'ExistingXccAccessorial',
	EXISTING_XCC_CORRECTION = 'ExistingXccCorrection',
	EXISTING_XLF_ACCESSORIAL = 'ExistingXlfAccessorial',
	EXISTING_XLF_CORRECTION = 'ExistingXlfCorrection',
	LARGEST_DIMS_UNDER_ELS_THRESHOLD = 'LargestDimsUnderElsThreshold',
	LINEAL_FOOT_INSPECTION_TYPE = 'LinealFootInspectionType',
	NO_DIMENSIONS = 'NoDimensions',
	PREVIOUS_INSPECTION = 'PreviousInspection',
	SHIPMENT_NOT_FINAL_DELIVERED = 'ShipmentNotFinalDelivered',
	SHIPMENT_NOT_FOUND = 'ShipmentNotFound',
	SHIPMENT_NOT_RATED = 'ShipmentNotRated'}
