import {FreightMaintenanceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class FreightMaintenanceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FreightMaintenanceTypeCd ) {
      this._values.push(FreightMaintenanceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FreightMaintenanceTypeCd {

    for ( const obj in FreightMaintenanceTypeCd ) {
      if (FreightMaintenanceTypeCd[obj] === value){
        return FreightMaintenanceTypeCd[obj] as FreightMaintenanceTypeCd;
      }
    }
  }
}

const FreightMaintenanceTypeCdHelper = new FreightMaintenanceTypeCdHelperEnumHelperClass();
export default FreightMaintenanceTypeCdHelper;
