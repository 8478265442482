
export enum CloseReasonCd {
	CANCELLED_PRO_RESET = 'cancelled_pro_reset',
	CMK_DISP_REGLTD_WSTE = 'cmk_disp_regltd_wste',
	DISPOSED_OF_AT_SIC = 'disposed_of_at_sic',
	DISP_FROM_SHIPPER = 'disp_from_shipper',
	DISP_TO_SEND_TO_CMK = 'disp_to_send_to_cmk',
	DUP_ENTRY_CNCL_ENTRY = 'dup_entry_cncl_entry',
	FINAL_DLVR_IN_FULL = 'final_dlvr_in_full',
	FINAL_DLVR_WITH_ASZZ = 'final_dlvr_with_aszz',
	FINAL_DLVR_WITH_PSZZ = 'final_dlvr_with_pszz',
	LOA_TO_RTRN_TO_SHIPR = 'loa_to_rtrn_to_shipr',
	MADE_GCBZ = 'made_gcbz',
	MADE_GCBZ_NEW_BILL = 'made_gcbz_new_bill',
	MATCHED_TO_PRO = 'matched_to_pro',
	MATCH_FOUND = 'match_found',
	MOVED_CURR_LOC_MOVR = 'moved_curr_loc_movr',
	NO_PARENT_FOUND = 'no_parent_found',
	PRO_NBR_FOUND = 'pro_nbr_found',
	RECONCILED_CROSS_PRO = 'reconciled_cross_pro',
	RECONSIGNMENT_PRCES = 'reconsignment_prces',
	RETURNED_TO_SHIPPER = 'returned_to_shipper',
	SALVAGE_RQST = 'salvage_rqst',
	SHPMNT_BLD_RDY_MOVE = 'shpmnt_bld_rdy_move',
	TAGGED_TO_PARENT_PRO = 'tagged_to_parent_pro'}
