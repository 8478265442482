import {TrailerRoutingEventCd} from '../';
import {EnumHelper} from './enum-helper';

export class TrailerRoutingEventCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TrailerRoutingEventCd ) {
      this._values.push(TrailerRoutingEventCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TrailerRoutingEventCd {

    for ( const obj in TrailerRoutingEventCd ) {
      if (TrailerRoutingEventCd[obj] === value){
        return TrailerRoutingEventCd[obj] as TrailerRoutingEventCd;
      }
    }
  }
}

const TrailerRoutingEventCdHelper = new TrailerRoutingEventCdHelperEnumHelperClass();
export default TrailerRoutingEventCdHelper;
