import {ShortChecklistStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShortChecklistStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShortChecklistStatusCd ) {
      this._values.push(ShortChecklistStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShortChecklistStatusCd {

    for ( const obj in ShortChecklistStatusCd ) {
      if (ShortChecklistStatusCd[obj] === value){
        return ShortChecklistStatusCd[obj] as ShortChecklistStatusCd;
      }
    }
  }
}

const ShortChecklistStatusCdHelper = new ShortChecklistStatusCdHelperEnumHelperClass();
export default ShortChecklistStatusCdHelper;
