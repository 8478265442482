import {OsdStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class OsdStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OsdStatusCd ) {
      this._values.push(OsdStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OsdStatusCd {

    for ( const obj in OsdStatusCd ) {
      if (OsdStatusCd[obj] === value){
        return OsdStatusCd[obj] as OsdStatusCd;
      }
    }
  }
}

const OsdStatusCdHelper = new OsdStatusCdHelperEnumHelperClass();
export default OsdStatusCdHelper;
