import {OtherOsdReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class OtherOsdReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OtherOsdReasonCd ) {
      this._values.push(OtherOsdReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OtherOsdReasonCd {

    for ( const obj in OtherOsdReasonCd ) {
      if (OtherOsdReasonCd[obj] === value){
        return OtherOsdReasonCd[obj] as OtherOsdReasonCd;
      }
    }
  }
}

const OtherOsdReasonCdHelper = new OtherOsdReasonCdHelperEnumHelperClass();
export default OtherOsdReasonCdHelper;
