import {DataStoreUseCd} from '../';
import {EnumHelper} from './enum-helper';

export class DataStoreUseCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DataStoreUseCd ) {
      this._values.push(DataStoreUseCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DataStoreUseCd {

    for ( const obj in DataStoreUseCd ) {
      if (DataStoreUseCd[obj] === value){
        return DataStoreUseCd[obj] as DataStoreUseCd;
      }
    }
  }
}

const DataStoreUseCdHelper = new DataStoreUseCdHelperEnumHelperClass();
export default DataStoreUseCdHelper;
