import {InspectionCorrectionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class InspectionCorrectionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InspectionCorrectionTypeCd ) {
      this._values.push(InspectionCorrectionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InspectionCorrectionTypeCd {

    for ( const obj in InspectionCorrectionTypeCd ) {
      if (InspectionCorrectionTypeCd[obj] === value){
        return InspectionCorrectionTypeCd[obj] as InspectionCorrectionTypeCd;
      }
    }
  }
}

const InspectionCorrectionTypeCdHelper = new InspectionCorrectionTypeCdHelperEnumHelperClass();
export default InspectionCorrectionTypeCdHelper;
