import {CorrectionWorkerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionWorkerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionWorkerTypeCd ) {
      this._values.push(CorrectionWorkerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionWorkerTypeCd {

    for ( const obj in CorrectionWorkerTypeCd ) {
      if (CorrectionWorkerTypeCd[obj] === value){
        return CorrectionWorkerTypeCd[obj] as CorrectionWorkerTypeCd;
      }
    }
  }
}

const CorrectionWorkerTypeCdHelper = new CorrectionWorkerTypeCdHelperEnumHelperClass();
export default CorrectionWorkerTypeCdHelper;
