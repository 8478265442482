import {TripStopActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripStopActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripStopActionCd ) {
      this._values.push(TripStopActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripStopActionCd {

    for ( const obj in TripStopActionCd ) {
      if (TripStopActionCd[obj] === value){
        return TripStopActionCd[obj] as TripStopActionCd;
      }
    }
  }
}

const TripStopActionCdHelper = new TripStopActionCdHelperEnumHelperClass();
export default TripStopActionCdHelper;
