
export enum SalvageRequestStatusCd {
	APPROVED_FOR_CMK = 'ApprovedForCMK',
	APPROVED_FOR_COMMON_DISPOSAL = 'ApprovedForCommonDisposal',
	APPROVED_FOR_COMPANY_USE = 'ApprovedForCompanyUse',
	APPROVED_FOR_CSV = 'ApprovedForCSV',
	APPROVED_FOR_REGULATED_DISPOSAL = 'ApprovedForRegulatedDisposal',
	CLOSED = 'Closed',
	CLOSED_NOT_APPROVED = 'ClosedNotApproved',
	CLOSED_RETURN_TO_CUSTOMER = 'ClosedReturnToCustomer',
	FEEDBACK_SUBMITTED = 'FeedbackSubmitted',
	INSPECTION_REPORT_NEEDED = 'InspectionReportNeeded',
	OPEN = 'Open',
	REQUEST_FOR_FEEDBACK = 'RequestForFeedback',
	REQUEST_FOR_PHOTOS = 'RequestForPhotos'}
