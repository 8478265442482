import {ShipmentPartyMatchActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentPartyMatchActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentPartyMatchActionCd ) {
      this._values.push(ShipmentPartyMatchActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentPartyMatchActionCd {

    for ( const obj in ShipmentPartyMatchActionCd ) {
      if (ShipmentPartyMatchActionCd[obj] === value){
        return ShipmentPartyMatchActionCd[obj] as ShipmentPartyMatchActionCd;
      }
    }
  }
}

const ShipmentPartyMatchActionCdHelper = new ShipmentPartyMatchActionCdHelperEnumHelperClass();
export default ShipmentPartyMatchActionCdHelper;
