import {AppointmentListActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class AppointmentListActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AppointmentListActionCd ) {
      this._values.push(AppointmentListActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AppointmentListActionCd {

    for ( const obj in AppointmentListActionCd ) {
      if (AppointmentListActionCd[obj] === value){
        return AppointmentListActionCd[obj] as AppointmentListActionCd;
      }
    }
  }
}

const AppointmentListActionCdHelper = new AppointmentListActionCdHelperEnumHelperClass();
export default AppointmentListActionCdHelper;
