
export enum PayformDetailCd {
	DRIVER_ACTIVITIES = 'DriverActivities',
	DRIVER_PAYFORMS = 'DriverPayforms',
	EMPLOYEE_DETAILS = 'EmployeeDetails',
	NON_DRIVE_TIME_TYPES = 'NonDriveTimeTypes',
	NOTES = 'Notes',
	SCHEDULES = 'Schedules',
	SCHEDULE_DETOURS = 'ScheduleDetours',
	SCHEDULE_TRAILERS = 'ScheduleTrailers',
	WORK_STANDARDS = 'WorkStandards'}

