import {NonDriveTimePaidCd} from '../';
import {EnumHelper} from './enum-helper';

export class NonDriveTimePaidCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NonDriveTimePaidCd ) {
      this._values.push(NonDriveTimePaidCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NonDriveTimePaidCd {

    for ( const obj in NonDriveTimePaidCd ) {
      if (NonDriveTimePaidCd[obj] === value){
        return NonDriveTimePaidCd[obj] as NonDriveTimePaidCd;
      }
    }
  }
}

const NonDriveTimePaidCdHelper = new NonDriveTimePaidCdHelperEnumHelperClass();
export default NonDriveTimePaidCdHelper;
