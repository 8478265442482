
export enum OsdStatusCd {
	D_CLOSED = 'd_closed',
	D_DISPO_NOT_RECEIVED = 'd_dispo_not_received',
	D_DISPO_RECEIVED = 'd_dispo_received',
	D_INSP_IN_PROGRESS = 'd_insp_in_progress',
	D_INSP_RECEIVED = 'd_insp_received',
	D_NOT_STARTED = 'd_not_started',
	D_ON_HAND_NOTICE = 'd_on_hand_notice',
	D_READY_TO_SHIP = 'd_ready_to_ship',
	D_SEND_SALVAGE_RQST = 'd_send_salvage_rqst',
	D_WAITING_FOR_SHPR = 'd_waiting_for_shpr',
	OT_BILLED = 'ot_billed',
	OT_BOL_IMGD = 'ot_bol_imgd',
	OT_BOL_RCVD = 'ot_bol_rcvd',
	OT_CLOSED = 'ot_closed',
	OT_CNCTD_LTL_BILL_TM = 'ot_cnctd_ltl_bill_tm',
	OT_CNCTD_ORGN_SIC = 'ot_cnctd_orgn_sic',
	OT_CNCTD_SHPR_DISP = 'ot_cnctd_shpr_disp',
	OT_CONTACT_SHIPPER = 'ot_contact_shipper',
	OT_CRCTD_BOL_RCVD = 'ot_crctd_bol_rcvd',
	OT_NOT_STARTED = 'ot_not_started',
	OT_ON_HAND_NOTICE = 'ot_on_hand_notice',
	OT_PROCESSING = 'ot_processing',
	OT_READY_TO_SHIP = 'ot_ready_to_ship',
	O_CLOSED = 'o_closed',
	O_CONS_FOUND = 'o_cons_found',
	O_ENTRY_IN_OVRG_APP = 'o_entry_in_ovrg_app',
	O_MATCH_FOUND = 'o_match_found',
	O_MATCH_NOT_FOUND = 'o_match_not_found',
	O_NOT_STARTED = 'o_not_started',
	O_READY_TO_SHIP = 'o_ready_to_ship',
	O_SALVAGE_APPROVAL = 'o_salvage_approval',
	O_SEND_SALVAGE_RQST = 'o_send_salvage_rqst',
	O_SHPR_FOUND = 'o_shpr_found',
	R_CLOSED = 'r_closed',
	R_DISPO_NOT_RECEIVED = 'r_dispo_not_received',
	R_DISPO_RECEIVED = 'r_dispo_received',
	R_INSP_IN_PROGRESS = 'r_insp_in_progress',
	R_INSP_RECEIVED = 'r_insp_received',
	R_NOT_STARTED = 'r_not_started',
	R_ON_HAND_NOTICE = 'r_on_hand_notice',
	R_READY_TO_SHIP = 'r_ready_to_ship',
	R_SEND_SALVAGE_RQST = 'r_send_salvage_rqst',
	R_WAITING_FOR_SHPR = 'r_waiting_for_shpr',
	S_APB_EMAIL_SENT = 's_apb_email_sent',
	S_CHECKLIST_COMPLETE = 's_checklist_complete',
	S_CLOSED = 's_closed',
	S_CONTACTED_CONS = 's_contacted_cons',
	S_CONTACTED_SHPR = 's_contacted_shpr',
	S_INITIATE_IMS_RPT = 's_initiate_ims_rpt',
	S_NOT_STARTED = 's_not_started',
	S_READY_TO_SHIP = 's_ready_to_ship',
	S_SHPMT_FOUND = 's_shpmt_found',
	S_SHPMT_NOT_FOUND = 's_shpmt_not_found'}
