import {BillCorrectionStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class BillCorrectionStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BillCorrectionStatusCd ) {
      this._values.push(BillCorrectionStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BillCorrectionStatusCd {

    for ( const obj in BillCorrectionStatusCd ) {
      if (BillCorrectionStatusCd[obj] === value){
        return BillCorrectionStatusCd[obj] as BillCorrectionStatusCd;
      }
    }
  }
}

const BillCorrectionStatusCdHelper = new BillCorrectionStatusCdHelperEnumHelperClass();
export default BillCorrectionStatusCdHelper;
