
export enum MetricValueKeyCd {
	COST_PER_CWT = 'CostPerCwt',
	DELIVERY_COST = 'DeliveryCost',
	DELIVERY_COST_PER_CWT = 'DeliveryCostPerCwt',
	DELIVERY_COST_PER_TRIP = 'DeliveryCostPerTrip',
	DELIVERY_MILES = 'DeliveryMiles',
	DELIVERY_MILES_PER_STOP = 'DeliveryMilesPerStop',
	DELIVERY_MOTOR_MOVES = 'DeliveryMotorMoves',
	DELIVERY_PERCENT_BY_1400 = 'DeliveryPercentBy1400',
	DELIVERY_PERCENT_BY_NOON = 'DeliveryPercentByNoon',
	DELIVERY_PPMH = 'DeliveryPpmh',
	DELIVERY_SERVICE_RISK_SHIPMENTS = 'DeliveryServiceRiskShipments',
	DELIVERY_STOPS_PER_HOUR = 'DeliveryStopsPerHour',
	DELIVERY_STOPS_PER_TRIP = 'DeliveryStopsPerTrip',
	DELIVERY_WEIGHT_PER_TRIP = 'DeliveryWeightPerTrip',
	G_G_12_SHIPMENTS_FOR_PLANNING = 'G!/G12ShipmentsForPlanning',
	LABOR_PN_D_COST = 'LaborPnDCost',
	LOAD_AVERAGE = 'LoadAverage',
	OUTBOUND_AND_INBOUND_CWT = 'OutboundAndInboundCWT',
	PN_D_WEIGHT = 'PnDWeight',
	PREM_SHIPMENTS_FOR_PLANNING = 'PremShipmentsForPlanning',
	SHOW_SHIPMENTS_FOR_PLANNING = 'ShowShipmentsForPlanning',
	TOTAL_BILLS = 'TotalBills',
	TOTAL_HOURS = 'TotalHours',
	TOTAL_ROUTES = 'TotalRoutes',
	TOTAL_STOPS = 'TotalStops',
	WFP_CITY_DELIVERY_TRIP = 'WFPCityDeliveryTrip',
	WFP_CITY_DELIVERY_WEIGHT = 'WFPCityDeliveryWeight'}

