
export enum CorrectionActionTypeCd {
	APPROVED = 'APPROVED',
	AUTO_APPROVED = 'AUTO_APPROVED',
	DE_ESCALATE = 'DE_ESCALATE',
	EXIT = 'EXIT',
	HOLD = 'HOLD',
	MORE_INFO = 'MORE_INFO',
	NOT_APPROVED = 'NOT_APPROVED',
	REASSIGN = 'REASSIGN',
	SAVED = 'SAVED',
	SUBMIT = 'SUBMIT',
	UNKNOWN = 'UNKNOWN',
	WIP = 'WIP'}
