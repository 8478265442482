
export enum ScheduleChangeReasonCd {
	ACCIDENT = 'ACCIDENT',
	BREAKDOWN = 'BREAKDOWN',
	CUSTOMS = 'CUSTOMS',
	DEPARTMENT_OF_TRANSPORTATION = 'DEPARTMENT OF TRANSPORTATION',
	DRIVER = 'DRIVER',
	ENTRY_ERROR = 'ENTRY ERROR',
	ETA_ADJUSTMENT = 'ETA ADJUSTMENT',
	HOURS_OF_SERVICE = 'HOURS OF SERVICE',
	LINEHAUL_CHANGE = 'LINEHAUL CHANGE',
	ROAD_CLOSURE = 'ROAD CLOSURE',
	SCHEDULE_TERMINATED = 'SCHEDULE TERMINATED',
	TRAFFIC = 'TRAFFIC',
	WEATHER = 'WEATHER'}
