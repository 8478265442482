import {LoadInstructionActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class LoadInstructionActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LoadInstructionActionCd ) {
      this._values.push(LoadInstructionActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LoadInstructionActionCd {

    for ( const obj in LoadInstructionActionCd ) {
      if (LoadInstructionActionCd[obj] === value){
        return LoadInstructionActionCd[obj] as LoadInstructionActionCd;
      }
    }
  }
}

const LoadInstructionActionCdHelper = new LoadInstructionActionCdHelperEnumHelperClass();
export default LoadInstructionActionCdHelper;
