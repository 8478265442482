import {ExtendedServiceLaneTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExtendedServiceLaneTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExtendedServiceLaneTypeCd ) {
      this._values.push(ExtendedServiceLaneTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExtendedServiceLaneTypeCd {

    for ( const obj in ExtendedServiceLaneTypeCd ) {
      if (ExtendedServiceLaneTypeCd[obj] === value){
        return ExtendedServiceLaneTypeCd[obj] as ExtendedServiceLaneTypeCd;
      }
    }
  }
}

const ExtendedServiceLaneTypeCdHelper = new ExtendedServiceLaneTypeCdHelperEnumHelperClass();
export default ExtendedServiceLaneTypeCdHelper;
