
export enum FinancialFunctionCd {
	ACROSS_SOB_ELIMINATIONS = 'ACROSS_SOB_ELIMINATIONS',
	ACROSS_SOB_THIRD_PARTY_CONTRAC = 'ACROSS_SOB_THIRD_PARTY_CONTRAC',
	ADMINISTRATIVE = 'ADMINISTRATIVE',
	ADMIN_SUPPORT = 'ADMIN_SUPPORT',
	BALANCE_SHEET = 'BALANCE_SHEET',
	CARTAGE = 'CARTAGE',
	CNET_POSTAL = 'CNET_POSTAL',
	CONSULTING = 'CONSULTING',
	CWF_SALES = 'CWF_SALES',
	DEDICATED_FLEET = 'DEDICATED_FLEET',
	DOMESTIC_OPS = 'DOMESTIC_OPS',
	E_COMMERCE = 'E_COMMERCE',
	FLIGHT_CREWS = 'FLIGHT_CREWS',
	FREIGHT_MANAGEMENT = 'FREIGHT_MANAGEMENT',
	GROUND_HANDLING = 'GROUND_HANDLING',
	HUB_FAC = 'HUB_FAC',
	INCENTIVE = 'INCENTIVE',
	INTEREST_OTHER = 'INTEREST_OTHER',
	INTL_OPERATIONS = 'INTL_OPERATIONS',
	LEGAL_VS_MGMT = 'LEGAL_VS_MGMT',
	LINEHAUL_OPS = 'LINEHAUL_OPS',
	MAINTENANCE_SHOPS = 'MAINTENANCE_SHOPS',
	MANUFACTURE_ASSEMBLY = 'MANUFACTURE_ASSEMBLY',
	OPS_SUPPORT = 'OPS_SUPPORT',
	OTHER_OPS = 'OTHER_OPS',
	PMPC = 'PMPC',
	PRINT_SHOP = 'PRINT_SHOP',
	RECLASS = 'RECLASS',
	REVENUE_AIRLINE = 'REVENUE_AIRLINE',
	REVENUE_SALES = 'REVENUE_SALES',
	SW_DEVELOPMENT = 'SW_DEVELOPMENT',
	TAX = 'TAX',
	TRUCKLOAD_OPERATIONS = 'TRUCKLOAD_OPERATIONS',
	WAREHOUSE = 'WAREHOUSE'}
