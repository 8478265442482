import {AgreementRequestTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class AgreementRequestTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AgreementRequestTypeCd ) {
      this._values.push(AgreementRequestTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AgreementRequestTypeCd {

    for ( const obj in AgreementRequestTypeCd ) {
      if (AgreementRequestTypeCd[obj] === value){
        return AgreementRequestTypeCd[obj] as AgreementRequestTypeCd;
      }
    }
  }
}

const AgreementRequestTypeCdHelper = new AgreementRequestTypeCdHelperEnumHelperClass();
export default AgreementRequestTypeCdHelper;
