import {InspectionDimensionsDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class InspectionDimensionsDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InspectionDimensionsDetailCd ) {
      this._values.push(InspectionDimensionsDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InspectionDimensionsDetailCd {

    for ( const obj in InspectionDimensionsDetailCd ) {
      if (InspectionDimensionsDetailCd[obj] === value){
        return InspectionDimensionsDetailCd[obj] as InspectionDimensionsDetailCd;
      }
    }
  }
}

const InspectionDimensionsDetailCdHelper = new InspectionDimensionsDetailCdHelperEnumHelperClass();
export default InspectionDimensionsDetailCdHelper;
