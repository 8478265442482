import {IdentifiedLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class IdentifiedLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in IdentifiedLocationTypeCd ) {
      this._values.push(IdentifiedLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): IdentifiedLocationTypeCd {

    for ( const obj in IdentifiedLocationTypeCd ) {
      if (IdentifiedLocationTypeCd[obj] === value){
        return IdentifiedLocationTypeCd[obj] as IdentifiedLocationTypeCd;
      }
    }
  }
}

const IdentifiedLocationTypeCdHelper = new IdentifiedLocationTypeCdHelperEnumHelperClass();
export default IdentifiedLocationTypeCdHelper;
