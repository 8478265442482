
export enum OtherOsdReasonCd {
	CROSSED = 'crossed',
	ENTRY_NOT_RQRD = 'entry_not_rqrd',
	FNL_IN_ERROR = 'fnl_in_error',
	ONLY_CHILD_PRO = 'only_child_pro',
	RCD_PROCESSED = 'rcd_processed',
	RTS_REQUESTED = 'rts_requested',
	UNBILLED = 'unbilled',
	UNDELIVERABLE = 'undeliverable'}
