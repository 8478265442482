import {LoadingOperationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LoadingOperationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LoadingOperationTypeCd ) {
      this._values.push(LoadingOperationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LoadingOperationTypeCd {

    for ( const obj in LoadingOperationTypeCd ) {
      if (LoadingOperationTypeCd[obj] === value){
        return LoadingOperationTypeCd[obj] as LoadingOperationTypeCd;
      }
    }
  }
}

const LoadingOperationTypeCdHelper = new LoadingOperationTypeCdHelperEnumHelperClass();
export default LoadingOperationTypeCdHelper;
