
export enum EquipmentTypeDetailCd {
	DOLLY_OWNED = 'DollyOwned',
	MISC_OTHER_FORKLIFT_OWNED = 'MiscOtherForkliftOwned',
	MISC_OTHER_HOSTLER_OWNED = 'MiscOtherHostlerOwned',
	MISC_OTHER_PICKUP_OWNED = 'MiscOtherPickupOwned',
	MISC_OTHER_SATELLITE_OWNED = 'MiscOtherSatelliteOwned',
	MISC_OTHER_SWEEPER_OWNED = 'MiscOtherSweeperOwned',
	MISC_OTHER_VAN_OWNED = 'MiscOtherVanOwned',
	POWER_HVY_DUTY_RENTAL = 'PowerHvyDutyRental',
	POWER_HVY_DUTY_SUBSVC = 'PowerHvyDutySubsvc',
	POWER_LGT_DUTY_RENTAL = 'PowerLgtDutyRental',
	POWER_LGT_DUTY_SUBSVCL = 'PowerLgtDutySubsvcl',
	POWER_LH_OWNED = 'PowerLHOwned',
	POWER_PN_D_OWNED = 'PowerPnDOwned',
	STRAIGHT_STRT_TRK_OWNED = 'StraightStrtTrkOwned',
	STRAIGHT_STRT_TRK_RENTAL = 'StraightStrtTrkRental',
	STRAIGHT_STRT_TRK_SUBSERVICE = 'StraightStrtTrkSubservice',
	X_28_LH_TRAILER_OWNED = 'x28LHTrailerOwned',
	X_28_LH_TRAILER_RENTAL = 'x28LHTrailerRental',
	X_28_LH_TRAILER_SUBSERVICE = 'x28LHTrailerSubservice',
	X_28_PN_D_TRAILER_RENTAL = 'x28PnDTrailerRental',
	X_28_PN_D_TRAILER_SUBSERVICE = 'x28PnDTrailerSubservice',
	X_28_PN_D_TRAILER_W_LIFT_GATE_OWNED = 'x28PnDTrailerWLiftGateOwned',
	X_32_LH_TRAILER_OWNED = 'x32LHTrailerOwned',
	X_32_LH_TRAILER_RENTAL = 'x32LHTrailerRental',
	X_32_LH_TRAILER_SUBSERVICE = 'x32LHTrailerSubservice',
	X_32_PN_D_TRAILER_OWNED = 'x32PnDTrailerOwned',
	X_32_PN_D_TRAILER_RENTAL = 'x32PnDTrailerRental',
	X_32_PN_D_TRAILER_SUBSERVICE = 'x32PnDTrailerSubservice',
	X_33_LH_TRAILER_OWNED = 'x33LHTrailerOwned',
	X_33_PN_D_TRAILER_OWNED = 'x33PnDTrailerOwned',
	X_42_LH_TRAILER_OWNED = 'x42LHTrailerOwned',
	X_42_LH_TRAILER_SUBSERVICE = 'x42LHTrailerSubservice',
	X_42_LH_TRAILTER_RENTAL = 'x42LHTrailterRental',
	X_42_PN_D_TRAILER_OWNED = 'x42PnDTrailerOwned',
	X_42_PN_D_TRAILER_RENTAL = 'x42PnDTrailerRental',
	X_45_LH_TRAILER_OWNED = 'x45LHTrailerOwned',
	X_45_LH_TRAILER_RENTAL = 'x45LHTrailerRental',
	X_45_LH_TRAILER_SUBSERVICE = 'x45LHTrailerSubservice',
	X_45_PN_D_TRAILER_OWNED = 'x45PnDTrailerOwned',
	X_45_PN_D_TRAILER_RENTAL = 'x45PnDTrailerRental',
	X_45_PN_D_TRAILER_SUBSERVICE = 'x45PnDTrailerSubservice',
	X_48_LH_TRAILER_OWNED = 'x48LHTrailerOwned',
	X_48_LH_TRAILER_RENTAL = 'x48LHTrailerRental',
	X_48_LH_TRAILER_SUBSERVICE = 'x48LHTrailerSubservice',
	X_48_PN_D_TRAILER_OWNED = 'x48PnDTrailerOwned',
	X_48_PN_D_TRAILER_RENTAL = 'x48PnDTrailerRental',
	X_48_PN_D_TRAILER_SUBSERVICE = 'x48PnDTrailerSubservice',
	X_53_LH_TRAILER_OWNED = 'x53LHTrailerOwned',
	X_53_LH_TRAILER_RENTAL = 'x53LHTrailerRental',
	X_53_LH_TRAILER_SUBSERVICE = 'x53LHTrailerSubservice',
	X_53_PN_D_TRAILER_RENTAL = 'x53PnDTrailerRental',
	X_53_PN_D_TRAILER_SUBSERVICE = 'x53PnDTrailerSubservice',
	X_53_PN_D_TTRAILER_OWNED = 'x53PnDTtrailerOwned'}

