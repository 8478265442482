import {CorrectionDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionDetailCd ) {
      this._values.push(CorrectionDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionDetailCd {

    for ( const obj in CorrectionDetailCd ) {
      if (CorrectionDetailCd[obj] === value){
        return CorrectionDetailCd[obj] as CorrectionDetailCd;
      }
    }
  }
}

const CorrectionDetailCdHelper = new CorrectionDetailCdHelperEnumHelperClass();
export default CorrectionDetailCdHelper;
