import {OsdCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class OsdCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OsdCategoryCd ) {
      this._values.push(OsdCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OsdCategoryCd {

    for ( const obj in OsdCategoryCd ) {
      if (OsdCategoryCd[obj] === value){
        return OsdCategoryCd[obj] as OsdCategoryCd;
      }
    }
  }
}

const OsdCategoryCdHelper = new OsdCategoryCdHelperEnumHelperClass();
export default OsdCategoryCdHelper;
