import {PartyReviewCustomerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PartyReviewCustomerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PartyReviewCustomerTypeCd ) {
      this._values.push(PartyReviewCustomerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PartyReviewCustomerTypeCd {

    for ( const obj in PartyReviewCustomerTypeCd ) {
      if (PartyReviewCustomerTypeCd[obj] === value){
        return PartyReviewCustomerTypeCd[obj] as PartyReviewCustomerTypeCd;
      }
    }
  }
}

const PartyReviewCustomerTypeCdHelper = new PartyReviewCustomerTypeCdHelperEnumHelperClass();
export default PartyReviewCustomerTypeCdHelper;
