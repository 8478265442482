import {DispatchChangeReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class DispatchChangeReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DispatchChangeReasonCd ) {
      this._values.push(DispatchChangeReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DispatchChangeReasonCd {

    for ( const obj in DispatchChangeReasonCd ) {
      if (DispatchChangeReasonCd[obj] === value){
        return DispatchChangeReasonCd[obj] as DispatchChangeReasonCd;
      }
    }
  }
}

const DispatchChangeReasonCdHelper = new DispatchChangeReasonCdHelperEnumHelperClass();
export default DispatchChangeReasonCdHelper;
