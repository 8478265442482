import {MessageTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class MessageTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MessageTypeCd ) {
      this._values.push(MessageTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MessageTypeCd {

    for ( const obj in MessageTypeCd ) {
      if (MessageTypeCd[obj] === value){
        return MessageTypeCd[obj] as MessageTypeCd;
      }
    }
  }
}

const MessageTypeCdHelper = new MessageTypeCdHelperEnumHelperClass();
export default MessageTypeCdHelper;
