import {ConeColorCd} from '../';
import {EnumHelper} from './enum-helper';

export class ConeColorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ConeColorCd ) {
      this._values.push(ConeColorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ConeColorCd {

    for ( const obj in ConeColorCd ) {
      if (ConeColorCd[obj] === value){
        return ConeColorCd[obj] as ConeColorCd;
      }
    }
  }
}

const ConeColorCdHelper = new ConeColorCdHelperEnumHelperClass();
export default ConeColorCdHelper;
