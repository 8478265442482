
export enum HandlingUnitMovementTypeCd {
	ADD = 'Add',
	CLOSE = 'Close',
	DELIVER = 'Deliver',
	DOCK_OPERATIONS_EXCEPTION = 'DockOperationsException',
	LOAD = 'Load',
	MOVE = 'Move',
	MOVER_SHIPMENT = 'MoverShipment',
	PICKUP = 'Pickup',
	REPLACE = 'Replace',
	SCAN = 'Scan',
	SPLIT = 'Split',
	STAGE = 'Stage',
	SWAP = 'Swap',
	TRANSFER = 'Transfer',
	UNLOAD = 'Unload',
	UNSPLIT = 'Unsplit'}
