import {NmftaRespMessageStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class NmftaRespMessageStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NmftaRespMessageStatusCd ) {
      this._values.push(NmftaRespMessageStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NmftaRespMessageStatusCd {

    for ( const obj in NmftaRespMessageStatusCd ) {
      if (NmftaRespMessageStatusCd[obj] === value){
        return NmftaRespMessageStatusCd[obj] as NmftaRespMessageStatusCd;
      }
    }
  }
}

const NmftaRespMessageStatusCdHelper = new NmftaRespMessageStatusCdHelperEnumHelperClass();
export default NmftaRespMessageStatusCdHelper;
