import {LicenseValidityCd} from '../';
import {EnumHelper} from './enum-helper';

export class LicenseValidityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LicenseValidityCd ) {
      this._values.push(LicenseValidityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LicenseValidityCd {

    for ( const obj in LicenseValidityCd ) {
      if (LicenseValidityCd[obj] === value){
        return LicenseValidityCd[obj] as LicenseValidityCd;
      }
    }
  }
}

const LicenseValidityCdHelper = new LicenseValidityCdHelperEnumHelperClass();
export default LicenseValidityCdHelper;
