
export enum CorrectionDetailCd {
	ACCESSORIAL = 'Accessorial',
	AC_AUTOMATION_EAM = 'AcAutomationEam',
	AC_AUTOMATION_SHIPLIFY = 'AcAutomationShiplify',
	ADVANCE_BEYOND = 'AdvanceBeyond',
	ALL = 'All',
	CANCEL_CHARGES = 'CancelCharges',
	CASH_COLLECTED_LINE = 'CashCollectedLine',
	CASH_PREPAID_LINE = 'CashPrepaidLine',
	CHARGE_TO_CODE = 'ChargeToCode',
	COD_AMOUNT = 'CodAmount',
	COMMODITY = 'Commodity',
	CUSTOMS_BOND = 'CustomsBond',
	DISCOUNT_AMOUNT = 'DiscountAmount',
	GUARANTEED_IND = 'GuaranteedInd',
	LINEAL_FEET = 'LinealFeet',
	MISCELLANEOUS_RATE = 'MiscellaneousRate',
	MISC_LINE_ITEM = 'MiscLineItem',
	MOTORIZED_PIECES = 'MotorizedPieces',
	PALLET_COUNT = 'PalletCount',
	REMARKS = 'Remarks',
	SHIPMENT_PARTIES = 'ShipmentParties',
	TARIFF_CODE = 'TariffCode',
	TERMINAL_SIC = 'TerminalSic',
	TIME_DATE_CRITICAL = 'TimeDateCritical',
	WEIGHT_ADJUSTMENT = 'WeightAdjustment'}
