import {AcorTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class AcorTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AcorTypeCd ) {
      this._values.push(AcorTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AcorTypeCd {

    for ( const obj in AcorTypeCd ) {
      if (AcorTypeCd[obj] === value){
        return AcorTypeCd[obj] as AcorTypeCd;
      }
    }
  }
}

const AcorTypeCdHelper = new AcorTypeCdHelperEnumHelperClass();
export default AcorTypeCdHelper;
