import {LoadGroupTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LoadGroupTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LoadGroupTypeCd ) {
      this._values.push(LoadGroupTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LoadGroupTypeCd {

    for ( const obj in LoadGroupTypeCd ) {
      if (LoadGroupTypeCd[obj] === value){
        return LoadGroupTypeCd[obj] as LoadGroupTypeCd;
      }
    }
  }
}

const LoadGroupTypeCdHelper = new LoadGroupTypeCdHelperEnumHelperClass();
export default LoadGroupTypeCdHelper;
