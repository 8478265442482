import {ShipmentUpdateActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentUpdateActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentUpdateActionCd ) {
      this._values.push(ShipmentUpdateActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentUpdateActionCd {

    for ( const obj in ShipmentUpdateActionCd ) {
      if (ShipmentUpdateActionCd[obj] === value){
        return ShipmentUpdateActionCd[obj] as ShipmentUpdateActionCd;
      }
    }
  }
}

const ShipmentUpdateActionCdHelper = new ShipmentUpdateActionCdHelperEnumHelperClass();
export default ShipmentUpdateActionCdHelper;
