import {EquipmentStatusQualifierCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentStatusQualifierCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentStatusQualifierCd ) {
      this._values.push(EquipmentStatusQualifierCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentStatusQualifierCd {

    for ( const obj in EquipmentStatusQualifierCd ) {
      if (EquipmentStatusQualifierCd[obj] === value){
        return EquipmentStatusQualifierCd[obj] as EquipmentStatusQualifierCd;
      }
    }
  }
}

const EquipmentStatusQualifierCdHelper = new EquipmentStatusQualifierCdHelperEnumHelperClass();
export default EquipmentStatusQualifierCdHelper;
