import {FreightOperationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class FreightOperationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FreightOperationTypeCd ) {
      this._values.push(FreightOperationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FreightOperationTypeCd {

    for ( const obj in FreightOperationTypeCd ) {
      if (FreightOperationTypeCd[obj] === value){
        return FreightOperationTypeCd[obj] as FreightOperationTypeCd;
      }
    }
  }
}

const FreightOperationTypeCdHelper = new FreightOperationTypeCdHelperEnumHelperClass();
export default FreightOperationTypeCdHelper;
