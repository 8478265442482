
export enum ShortChecklistStepCd {
	ADD_ANALYSE_OVRG_PHOTOS = 'add_analyse_ovrg_photos',
	CONTACT_CONSIGNEE_TO_INQR_ON_PO = 'contact_consignee_to_inqr_on_po',
	ENTER_TRAILER_PHOTOS = 'enter_trailer_photos',
	FILTER_FOR_DELIVERY_DATE = 'filter_for_delivery_date',
	MNTN_DOC_OF_COMMUNICATIONS = 'mntn_doc_of_communications',
	NOTE_TRAILER_DOCK_INFO = 'note_trailer_dock_info',
	PERFORM_DOCK_CHECK = 'perform_dock_check',
	REVIEW_ALL_AVAILABLE_DOCUMENTS = 'review_all_available_documents',
	REVIEW_ALL_SHPMNTS_DLVRD = 'review_all_shpmnts_dlvrd',
	REVIEW_DAILY_YARD_CHECK = 'review_daily_yard_check',
	REVIEW_DRS_FOR_OVRGS = 'review_drs_for_ovrgs',
	REVIEW_SHIPMENTS_PICKED_UP = 'review_shipments_picked_up',
	SEARCH_FOR_MATCHES_OVRG_APP = 'search_for_matches_ovrg_app',
	SIGN_CHECKLIST_AND_DR = 'sign_checklist_and_dr',
	SUBMIT_REQUEST_FOR_APB = 'submit_request_for_apb'}
