
export enum InspectionCorrectionTypeCd {
	CUBIC_CAPACITY = 'CubicCapacity',
	DENSITY_BILL = 'DensityBill',
	DENSITY_CUST = 'DensityCust',
	ELS = 'ELS',
	ELS_NON_INSP = 'ELSNonInsp',
	ITEM_15 = 'Item15',
	LINEAL_FOOT = 'LinealFoot',
	NMFC_BILL = 'NmfcBill',
	NMFC_CUST = 'NmfcCust'}
