import {HandlingUnitDimensionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class HandlingUnitDimensionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HandlingUnitDimensionTypeCd ) {
      this._values.push(HandlingUnitDimensionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HandlingUnitDimensionTypeCd {

    for ( const obj in HandlingUnitDimensionTypeCd ) {
      if (HandlingUnitDimensionTypeCd[obj] === value){
        return HandlingUnitDimensionTypeCd[obj] as HandlingUnitDimensionTypeCd;
      }
    }
  }
}

const HandlingUnitDimensionTypeCdHelper = new HandlingUnitDimensionTypeCdHelperEnumHelperClass();
export default HandlingUnitDimensionTypeCdHelper;
