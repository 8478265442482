import {DependentFreightTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DependentFreightTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DependentFreightTypeCd ) {
      this._values.push(DependentFreightTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DependentFreightTypeCd {

    for ( const obj in DependentFreightTypeCd ) {
      if (DependentFreightTypeCd[obj] === value){
        return DependentFreightTypeCd[obj] as DependentFreightTypeCd;
      }
    }
  }
}

const DependentFreightTypeCdHelper = new DependentFreightTypeCdHelperEnumHelperClass();
export default DependentFreightTypeCdHelper;
