
export enum GlobalSearchDetailCd {
	CLAIM_RESULT = 'ClaimResult',
	DISPUTE_RESULT = 'DisputeResult',
	DMS_RESULT = 'DMSResult',
	INVOICE_RESULT = 'InvoiceResult',
	OSD_RESULT = 'OsdResult',
	PICKUP_RESULT = 'PickupResult',
	PN_D_RESULT = 'PnDResult',
	SHIPMENT_RESULT = 'ShipmentResult'}

