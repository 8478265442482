import {ExpireReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExpireReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExpireReasonCd ) {
      this._values.push(ExpireReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExpireReasonCd {

    for ( const obj in ExpireReasonCd ) {
      if (ExpireReasonCd[obj] === value){
        return ExpireReasonCd[obj] as ExpireReasonCd;
      }
    }
  }
}

const ExpireReasonCdHelper = new ExpireReasonCdHelperEnumHelperClass();
export default ExpireReasonCdHelper;
