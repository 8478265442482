import {OverageApprovalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class OverageApprovalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OverageApprovalStatusCd ) {
      this._values.push(OverageApprovalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OverageApprovalStatusCd {

    for ( const obj in OverageApprovalStatusCd ) {
      if (OverageApprovalStatusCd[obj] === value){
        return OverageApprovalStatusCd[obj] as OverageApprovalStatusCd;
      }
    }
  }
}

const OverageApprovalStatusCdHelper = new OverageApprovalStatusCdHelperEnumHelperClass();
export default OverageApprovalStatusCdHelper;
