import {NonDriveTimeLocationCd} from '../';
import {EnumHelper} from './enum-helper';

export class NonDriveTimeLocationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NonDriveTimeLocationCd ) {
      this._values.push(NonDriveTimeLocationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NonDriveTimeLocationCd {

    for ( const obj in NonDriveTimeLocationCd ) {
      if (NonDriveTimeLocationCd[obj] === value){
        return NonDriveTimeLocationCd[obj] as NonDriveTimeLocationCd;
      }
    }
  }
}

const NonDriveTimeLocationCdHelper = new NonDriveTimeLocationCdHelperEnumHelperClass();
export default NonDriveTimeLocationCdHelper;
