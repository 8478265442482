import {AppointmentActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class AppointmentActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AppointmentActionCd ) {
      this._values.push(AppointmentActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AppointmentActionCd {

    for ( const obj in AppointmentActionCd ) {
      if (AppointmentActionCd[obj] === value){
        return AppointmentActionCd[obj] as AppointmentActionCd;
      }
    }
  }
}

const AppointmentActionCdHelper = new AppointmentActionCdHelperEnumHelperClass();
export default AppointmentActionCdHelper;
