import {InspectionCorrectionIneligibleReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class InspectionCorrectionIneligibleReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InspectionCorrectionIneligibleReasonCd ) {
      this._values.push(InspectionCorrectionIneligibleReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InspectionCorrectionIneligibleReasonCd {

    for ( const obj in InspectionCorrectionIneligibleReasonCd ) {
      if (InspectionCorrectionIneligibleReasonCd[obj] === value){
        return InspectionCorrectionIneligibleReasonCd[obj] as InspectionCorrectionIneligibleReasonCd;
      }
    }
  }
}

const InspectionCorrectionIneligibleReasonCdHelper = new InspectionCorrectionIneligibleReasonCdHelperEnumHelperClass();
export default InspectionCorrectionIneligibleReasonCdHelper;
