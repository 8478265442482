import {ShipmentPremiumServiceCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentPremiumServiceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentPremiumServiceCd ) {
      this._values.push(ShipmentPremiumServiceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentPremiumServiceCd {

    for ( const obj in ShipmentPremiumServiceCd ) {
      if (ShipmentPremiumServiceCd[obj] === value){
        return ShipmentPremiumServiceCd[obj] as ShipmentPremiumServiceCd;
      }
    }
  }
}

const ShipmentPremiumServiceCdHelper = new ShipmentPremiumServiceCdHelperEnumHelperClass();
export default ShipmentPremiumServiceCdHelper;
