import {DimensionSourceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DimensionSourceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DimensionSourceTypeCd ) {
      this._values.push(DimensionSourceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DimensionSourceTypeCd {

    for ( const obj in DimensionSourceTypeCd ) {
      if (DimensionSourceTypeCd[obj] === value){
        return DimensionSourceTypeCd[obj] as DimensionSourceTypeCd;
      }
    }
  }
}

const DimensionSourceTypeCdHelper = new DimensionSourceTypeCdHelperEnumHelperClass();
export default DimensionSourceTypeCdHelper;
