import {CorrectionActionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionActionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionActionTypeCd ) {
      this._values.push(CorrectionActionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionActionTypeCd {

    for ( const obj in CorrectionActionTypeCd ) {
      if (CorrectionActionTypeCd[obj] === value){
        return CorrectionActionTypeCd[obj] as CorrectionActionTypeCd;
      }
    }
  }
}

const CorrectionActionTypeCdHelper = new CorrectionActionTypeCdHelperEnumHelperClass();
export default CorrectionActionTypeCdHelper;
