import {DeliveryServiceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeliveryServiceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeliveryServiceTypeCd ) {
      this._values.push(DeliveryServiceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeliveryServiceTypeCd {

    for ( const obj in DeliveryServiceTypeCd ) {
      if (DeliveryServiceTypeCd[obj] === value){
        return DeliveryServiceTypeCd[obj] as DeliveryServiceTypeCd;
      }
    }
  }
}

const DeliveryServiceTypeCdHelper = new DeliveryServiceTypeCdHelperEnumHelperClass();
export default DeliveryServiceTypeCdHelper;
