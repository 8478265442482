import {FinancialFunctionCd} from '../';
import {EnumHelper} from './enum-helper';

export class FinancialFunctionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FinancialFunctionCd ) {
      this._values.push(FinancialFunctionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FinancialFunctionCd {

    for ( const obj in FinancialFunctionCd ) {
      if (FinancialFunctionCd[obj] === value){
        return FinancialFunctionCd[obj] as FinancialFunctionCd;
      }
    }
  }
}

const FinancialFunctionCdHelper = new FinancialFunctionCdHelperEnumHelperClass();
export default FinancialFunctionCdHelper;
