import {SatelliteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class SatelliteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SatelliteTypeCd ) {
      this._values.push(SatelliteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SatelliteTypeCd {

    for ( const obj in SatelliteTypeCd ) {
      if (SatelliteTypeCd[obj] === value){
        return SatelliteTypeCd[obj] as SatelliteTypeCd;
      }
    }
  }
}

const SatelliteTypeCdHelper = new SatelliteTypeCdHelperEnumHelperClass();
export default SatelliteTypeCdHelper;
