import {SafetyAwardTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class SafetyAwardTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SafetyAwardTypeCd ) {
      this._values.push(SafetyAwardTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SafetyAwardTypeCd {

    for ( const obj in SafetyAwardTypeCd ) {
      if (SafetyAwardTypeCd[obj] === value){
        return SafetyAwardTypeCd[obj] as SafetyAwardTypeCd;
      }
    }
  }
}

const SafetyAwardTypeCdHelper = new SafetyAwardTypeCdHelperEnumHelperClass();
export default SafetyAwardTypeCdHelper;
