import {FreightServiceAreaTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class FreightServiceAreaTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FreightServiceAreaTypeCd ) {
      this._values.push(FreightServiceAreaTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FreightServiceAreaTypeCd {

    for ( const obj in FreightServiceAreaTypeCd ) {
      if (FreightServiceAreaTypeCd[obj] === value){
        return FreightServiceAreaTypeCd[obj] as FreightServiceAreaTypeCd;
      }
    }
  }
}

const FreightServiceAreaTypeCdHelper = new FreightServiceAreaTypeCdHelperEnumHelperClass();
export default FreightServiceAreaTypeCdHelper;
