/**
 * BillOfLading API
 * Version: 1.0
 * Build: Manual
 */

import {
	DataValidationError,
	HyperMedia,
	ListInfo,
	File,
	PartyContactBasic,
	CommodityInfo,
	MonetaryAmount,
	Phone,
	ReferenceNumber,
	ShipmentParty,
	ContactInfo,
	Weight,
	SupplementalReference,
	CommodityLine,
	Accessorial,
	Charge,
	ContactInfoBasic,
	Volume,
	Dimensions,
	Person,
	Comment,
	EquipmentInfo,
	PartyBasic,
	Address,
	ChargeToCd,
	CommodityClassCd,
	CommodityPackageCd,
	MiscLineItemPaymentMethodCd,
	ShipmentRemarkTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * Request message to create Bill Of Lading and Pickup Request from EDI 204 and/or 211 transaction
 */
export class CreateEdiBillOfLadingPickupRequestRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	ediBolHdr:EdiBolHeader = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	bolDetail:Bol = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
}
export class CreateEdiBillOfLadingPickupRequestResp {
	/**
	 * Unique Identifier of the Bill of Lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Unique identifier of the Pickup Request
	 * Required: false
	 * Array: false
	 */
	pkupInstId:string = undefined;
	/**
	 * Service Center (SIC) responsible for Pickup
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSic:string = undefined;
	/**
	 * Date when the pickup request was called in
	 * Required: false
	 * Array: false
	 */
	pkupCallDate:Date = undefined;
	/**
	 * Call sequence number of the Pickup request
	 * Required: false
	 * Array: false
	 */
	pkupCallSeq:number = undefined;
	/**
	 * Pro Number Text
	 * 
	 * PRO_NBR_TXT
	 * 	
	 * Desc: Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
}
/**
 * Cancels a Bill of Lading and associated Pickup Request for an EDI customer
 */
export class CancelEdiBillOfLadingPickupRequestRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	allowUpdateCancelInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediDocType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediSenderShpId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	evtCreateTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	evtTypeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	messageTypeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origTrmnlSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	templateSrcCd:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class CancelEdiBillOfLadingPickupRequestResp {
	/**
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
}
/**
 * Maintains Bill of Lading and Pickup Request for EDI GENTRAN (via Batch) inbound customer request. Based on the inbound 204 and/or 211 message, either the Bill of Lading record or Pickup Request or both will be created, updated or cancelled. Corresponding identifiers and Error messages are sent back in the response that will be used for a 990.
 */
export class RecordEdiBatchBillOfLadingPickupRequestRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	ediBolHdr:EdiBolHeader = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	bolDetail:Bol = undefined;
}
/**
 * Maintains Bill of Lading and Pickup Request for EDI GENTRAN (via Batch) inbound customer request. Based on the inbound 204 and/or 211 message, either the Bill of Lading record or Pickup Request or both will be created, updated or cancelled. Corresponding identifiers and Error messages are sent back in the response that will be used for a 990.
 */
export class RecordEdiBatchBillOfLadingPickupRequestResp {
	/**
	 * Unique Identifier of the Bill of Lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Unique identifier of the Pickup Request
	 * Required: false
	 * Array: false
	 */
	pkupInstId:string = undefined;
	/**
	 * Service Center (SIC) responsible for Pickup
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSic:string = undefined;
	/**
	 * Date when the pickup request was called in
	 * Required: false
	 * Array: false
	 */
	pkupCallDate:Date = undefined;
	/**
	 * Call sequence number of the Pickup request
	 * Required: false
	 * Array: false
	 */
	pkupCallSeq:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	bolErrorMessages:DataValidationError[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	purErrorMessages:DataValidationError[] = undefined;
}
/**
 * Request message to create Bill Of Lading Templates
 */
export class CreateBillOfLadingTemplateRqst {
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bolTemplate:BolBasic = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class CreateBillOfLadingTemplateResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:CreateBillOfLadingTemplateResp_BolPickupReqId = undefined;
}
/**
 * Request message to create Bill Of Lading Templates
 */
export class UpdateBillOfLadingTemplateRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bolTemplate:BolBasic = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class UpdateBillOfLadingTemplateResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:UpdateBillOfLadingTemplateResp_BolPickupReqId = undefined;
}
/**
 * Request message to retrieve list of Bill of Lading templates for a given requester id
 */
export class ListBillOfLadingTemplatesRqst {
	/**
	 * Registered user identifier
	 * Required: true
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response message to retrieve list of Bill of Lading templates for a given requester id
 */
export class ListBillOfLadingTemplatesResp {
	/**
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Information about the Bill of Lading to be created
	 * Required: false
	 * Array: true
	 */
	bolTemplate:ListBillOfLadingTemplatesResp_BolBasic[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to retrieve list of Bill of Lading templates for a given requester id
 */
export class ListBillOfLadingRqst {
	/**
	 * Registered user identifier
	 * Required: true
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response message to retrieve list of Bill of Lading templates for a given requester id
 */
export class ListBillOfLadingResp {
	/**
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	bolList:BolBasic[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to delete an existing Bill of Lading template
 */
export class DeleteBillOfLadingTemplateRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to retrieve list of Bill of Lading for a given requester id
 */
export class ListSubmittedBillsOfLadingRqst {
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response message to retrieve list of Bill of Lading for a given requester id
 */
export class ListSubmittedBillsOfLadingResp {
	/**
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Information about the Bill of Lading documents
	 * Required: false
	 * Array: true
	 */
	bol:ListSubmittedBillsOfLadingResp_BolBasic[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to create Bill Of Lading
 */
export class CreateBillOfLadingRqst {
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bol:BolBasic = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class CreateBillOfLadingResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:CreateBillOfLadingResp_BolPickupReqId = undefined;
}
/**
 * Request message to create Bill Of Lading
 */
export class CreateOcrBillOfLadingRqst {
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bol:BolBasic = undefined;
}
export class CreateOcrBillOfLadingResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:CreateOcrBillOfLadingResp_BolPickupReqId = undefined;
}
/**
 * Request message to update and existing Bill Of Lading
 */
export class UpdateBillOfLadingRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bol:BolBasic = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class UpdateBillOfLadingResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:UpdateBillOfLadingResp_BolPickupReqId = undefined;
}
/**
 * Request message to cancel an existing Bill Of Lading
 */
export class CancelBillOfLadingRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to retrieve details of an existing Bill Of Lading
 */
export class GetBillOfLadingRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class GetBillOfLadingResp {
	/**
	 * Required: false
	 * Array: false
	 */
	billOfLading:BolBasic = undefined;
}
/**
 * Request message to generate PDF for an existing Bill Of Lading
 */
export class GetBillOfLadingPdfRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Flag for requester to sign the PDF or not
	 * Required: false
	 * Array: false
	 */
	requesterToSign:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to retrieve details of an existing Bill Of Lading
 */
export class GetBillOfLadingTemplateRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class GetBillOfLadingTemplateResp {
	/**
	 * Required: false
	 * Array: false
	 */
	bolTemplate:BolBasic = undefined;
}
export class GetBillOfLadingPdfResp {
	/**
	 * Required: false
	 * Array: false
	 */
	bolpdf:File = undefined;
}
/**
 * Request message to retrieve details of an existing Bill Of Lading
 */
export class GetBolDetailsRqst {
	/**
	 * Identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The original Pro Number supplied by the customer (i.e. NOT the Pro Number issued by XPO).
	 * Required: false
	 * Array: false
	 */
	customerSuppliedProNbr:string = undefined;
}
export class GetBolDetailsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	bolPrimaryDetails:BolDetail = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	bolCommodityDetails:BolCommodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	bolAccessorialDetails:BolAccessorial[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	bolSupplementalRefDetails:BolSupplementalRef[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	bolPickupRequestDetails:BolPickupRequest[] = undefined;
}
/**
 * Request message to generate PDF for an existing Bill Of Lading
 */
export class EmailBillOfLadingPdfRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * If email id is provided the generated PDF will be sent to this email.
	 * If email id is not provided then generated pdf will be sent to Requester email address in the BOL Document
	 * Required: false
	 * Array: false
	 */
	emailId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to generate PDF for an existing Bill Of Lading
 */
export class EmailBillOfLadingPdfResp {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
}
/**
 * Request message to generate the PDF for PAPS label
 */
export class CreatePapsLabelPdfRqst {
	/**
	 * Number of labels to print
	 * Required: false
	 * Array: false
	 */
	nbrOfLabels:number = undefined;
	/**
	 * Valid values: 1 thru 18
	 * Required: false
	 * Array: false
	 */
	labelPosition:number = undefined;
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class CreatePapsLabelPdfResp {
	/**
	 * Required: false
	 * Array: false
	 */
	papsLabel:File = undefined;
}
/**
 * Request message to email the PAPS label
 */
export class EmailPapsLabelPdfRqst {
	/**
	 * Number of labels to print
	 * Required: false
	 * Array: false
	 */
	nbrOfLabels:number = undefined;
	/**
	 * Valid values: 1 thru 18
	 * Required: false
	 * Array: false
	 */
	labelPosition:number = undefined;
	/**
	 * If email id is provided the generated PDF will be sent to this email.
	 * If email id is not provided then generated pdf will be sent to Requester email address in the BOL Document
	 * Required: false
	 * Array: false
	 */
	emailId:string = undefined;
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to generate PDF for an existing Bill Of Lading
 */
export class EmailPapsLabelPdfResp {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
}
/**
 * Request message to email the PAPS label
 */
export class EmailShippingLabelPdfRqst {
	/**
	 * Number of labels to print
	 * Required: false
	 * Array: false
	 */
	nbrOfLabels:number = undefined;
	/**
	 * If email id is provided the generated PDF will be sent to this email.
	 * If email id is not provided then generated pdf will be sent to Requester email address in the BOL Document
	 * Required: false
	 * Array: false
	 */
	emailId:string = undefined;
	/**
	 * Valid values: 
	 * 1 thru 6
	 * Required: false
	 * Array: false
	 */
	labelPosition:number = undefined;
	/**
	 * Valid values: 
	 * Normal, Alternate1, Alternate2, Zebra
	 * Required: false
	 * Array: false
	 */
	printerSettings:string = undefined;
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to generate PDF for an existing Bill Of Lading
 */
export class EmailShippingLabelPdfResp {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
}
/**
 * Request message to generate the PDF for Shipping Label
 */
export class CreateShippingLabelPdfRqst {
	/**
	 * Number of labels to print
	 * Required: false
	 * Array: false
	 */
	nbrOfLabels:number = undefined;
	/**
	 * Valid values: 
	 * 1 thru 6
	 * Required: false
	 * Array: false
	 */
	labelPosition:number = undefined;
	/**
	 * Valid values: 
	 * Normal, Alternate1, Alternate2, Zebra
	 * Required: false
	 * Array: false
	 */
	printerSettings:string = undefined;
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class CreateShippingLabelPdfResp {
	/**
	 * Required: false
	 * Array: false
	 */
	shippingLabel:File = undefined;
}
/**
 * Request to create commodity preference for the user
 */
export class CreateBOLCommodityPreferenceRqst {
	/**
	 * Required: false
	 * Array: true
	 */
	commodities:BOLCommodityBasic[] = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response to create commodity preference for the user
 */
export class CreateBOLCommodityPreferenceResp {
	/**
	 * Required: false
	 * Array: true
	 */
	commodities:BOLCommodityBasic[] = undefined;
}
/**
 * Request to update commodity preference for the user
 */
export class UpdateBOLCommodityPreferenceRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	commodity:BOLCommodityBasic = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * identifier for the bol commodity
	 * Required: true
	 * Array: false
	 */
	sequenceId:string = undefined;
}
/**
 * Response to update commodity preference for the user
 */
export class UpdateBOLCommodityPreferenceResp {
	/**
	 * Required: true
	 * Array: false
	 */
	commodity:BOLCommodityBasic = undefined;
}
/**
 * Request to delete commodity preference for the user
 */
export class DeleteBOLCommodityPreferenceRqst {
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Identifier for the bol commodity
	 * Required: true
	 * Array: false
	 */
	sequenceId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request to list commodities for the user
 */
export class ListBOLCommodityPreferencesRqst {
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response to list commodities for the user
 */
export class ListBOLCommodityPreferencesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:BOLCommodityBasic[] = undefined;
}
/**
 * Request to list commodities for the user
 */
export class UploadBillOfLadingTemplatesRqst {
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Contents of the file to be uploaded.
	 * Required: false
	 * Array: false
	 */
	fileContents:File = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
	/**
	 * Valid values are Y or N. Flag to indicate if the request needs to be validated
	 * Required: false
	 * Array: false
	 */
	validateRequest:string = undefined;
}
export class UploadBillOfLadingTemplatesResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: true
	 */
	bolInfo:UploadBillOfLadingTemplatesResp_BolPickupReqId[] = undefined;
}
/**
 * Request to list commodities for the user
 */
export class UploadBOLCommodityPreferencesRqst {
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Contents of the file to be uploaded.
	 * Required: false
	 * Array: false
	 */
	fileContents:File = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
	/**
	 * Valid values are Y or N. Flag to indicate if the request needs to be validated
	 * Required: false
	 * Array: false
	 */
	validateRequest:string = undefined;
}
export class UploadBOLCommodityPreferencesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:BOLCommodityBasic[] = undefined;
}
/**
 * Request message to generate the PDF for a shipping label.
 */
export class GenerateShippingLabelPdfRqst {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: true
	 */
	bolInstId:string[] = undefined;
	/**
	 * Number of labels to print
	 * Required: false
	 * Array: false
	 */
	nbrOfLabels:number = undefined;
	/**
	 * Valid values: 
	 * 1 thru 6
	 * Required: false
	 * Array: false
	 */
	labelPosition:number = undefined;
	/**
	 * Valid values: 
	 * Normal, Alternate1, Alternate2, Zebra
	 * Required: false
	 * Array: false
	 */
	printerSettings:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response to the request to generate the PDF for a shipping label.
 */
export class GenerateShippingLabelPdfResp {
	/**
	 * Required: false
	 * Array: true
	 */
	unMatchedBolInstIds:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileContents:BolFile = undefined;
}
/**
 * Request message to generate a PDF for an existing bill of lading.
 */
export class GenerateBillOfLadingPdfRqst {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: true
	 */
	bolInstId:string[] = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Flag for requester to sign the PDF or not
	 * Required: false
	 * Array: false
	 */
	requesterToSign:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Response to the request to generate a PDF for an existing bill of lading.
 */
export class GenerateBillOfLadingPdfResp {
	/**
	 * Required: false
	 * Array: true
	 */
	unMatchedBolInstIds:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileContents:BolFile = undefined;
}
/**
 * Request message to generate the PDF for PAPS label
 */
export class GeneratePapsLabelPdfRqst {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: true
	 */
	bolInstId:string[] = undefined;
	/**
	 * Number of labels to print
	 * Required: false
	 * Array: false
	 */
	nbrOfLabels:number = undefined;
	/**
	 * Valid values: 1 thru 18
	 * Required: false
	 * Array: false
	 */
	labelPosition:number = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class GeneratePapsLabelPdfResp {
	/**
	 * Required: false
	 * Array: true
	 */
	unMatchedBolInstIds:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileContents:BolFile = undefined;
}
/**
 * Request message to create Bill Of Lading Templates
 */
export class CopyBillOfLadingTemplatesRqst {
	/**
	 * username from whose account the templates are copied from
	 * Required: false
	 * Array: false
	 */
	copyFromUser:string = undefined;
	/**
	 * username from whose account the templates are copied to
	 * Required: false
	 * Array: false
	 */
	copyToUser:string = undefined;
	/**
	 * identifier for the bill of lading templates
	 * Required: false
	 * Array: true
	 */
	bolInstId:string[] = undefined;
}
export class CopyBillOfLadingTemplatesResp {
	/**
	 * Bill Of Lading template details.
	 * Required: false
	 * Array: true
	 */
	bolInfo:CopyBillOfLadingTemplatesResp_BolPickupReqId[] = undefined;
}
/**
 * Request message to generate Bill Of Lading CSV
 */
export class GenerateBillOfLadingCsvRqst {
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Identifier for the bill of lading
	 * Required: false
	 * Array: true
	 */
	bolInstId:string[] = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class GenerateBillOfLadingCsvResp {
	/**
	 * Required: false
	 * Array: false
	 */
	fileContents:File = undefined;
}
/**
 * Request message to create Bill Of Lading Templates
 */
export class CreateBillOfLadingDraftRqst {
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bolDraft:BolBasic = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class CreateBillOfLadingDraftResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:CreateBillOfLadingDraftResp_BolPickupReqId = undefined;
}
/**
 * Request message to delete an existing Bill of Lading template
 */
export class DeleteBillOfLadingDraftRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
/**
 * Request message to create Bill Of Lading Templates
 */
export class UpdateBillOfLadingDraftRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Information about the Bill of Lading to be created
	 * Required: true
	 * Array: false
	 */
	bolDraft:BolBasic = undefined;
	/**
	 * True - If Pro Number should be automatically assigned by XPO LTL. False - If manually entering the Pro or supplied by DSR
	 * Required: false
	 * Array: false
	 */
	autoAssignPro:boolean = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class UpdateBillOfLadingDraftResp {
	/**
	 * Bill Of Lading and Pickup Request Identification details.
	 * Required: false
	 * Array: false
	 */
	bolInfo:UpdateBillOfLadingDraftResp_BolPickupReqId = undefined;
}
/**
 * Request message to retrieve details of an existing Bill Of Lading
 */
export class GetBillOfLadingDraftRqst {
	/**
	 * identifier for the bill of lading
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Registered user identifier
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * Valid values are Y or N. If set to Y then gateway will route to Test server instead of Production
	 * Required: false
	 * Array: false
	 */
	testMode:string = undefined;
}
export class GetBillOfLadingDraftResp {
	/**
	 * Required: false
	 * Array: false
	 */
	bolDraft:BolBasic = undefined;
}
/**
 * Request message to retrieve details of an existing Bill Of Lading
 */
export class GetSuppRefNumbersRqst {
	/**
	 * The identifier for the bill of lading.
	 * Required: true
	 * Array: false
	 */
	bolInstId:string = undefined;
}
export class GetSuppRefNumbersResp {
	/**
	 * The supplemental references associated with the input provided.
	 * Required: false
	 * Array: true
	 */
	suppRefs:BolSupplementalReference[] = undefined;
}
/**
 * To be used as type for uploading/downloading files.
 */
export class BolFile {
	/**
	 * Name of the file.
	 * Required: false
	 * Array: false
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 * Required: false
	 * Array: false
	 */
	contentType:string = undefined;
	/**
	 * Base 64 file contents.
	 * Required: false
	 * Array: false
	 */
	fileContents:string = undefined;
}
export class BolSupplementalReference {
	/**
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refSeqNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refDesc:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refNbrTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refTypeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suprefPrintIncludeInd:string = undefined;
}
/**
 * Information about additional value added services requested
 */
export class UploadStatus {
	/**
	 * Examples: SUCCESS, ERROR.
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
}
/**
 * Information about additional value added services requested
 */
export class BOLCommodityBasic {
	/**
	 * Sequence ID to the commodity
	 * Required: false
	 * Array: false
	 */
	sequenceId:string = undefined;
	/**
	 * BOL Commodity description
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Indicator for hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * packaging type for the commodity :
	 * CTN : Carton
	 * PLT : Pallet
	 * PCS : Pieces
	 * SKD : Skid
	 * BAG : Bag
	 * BAL : Bale
	 * BBL : Barrel
	 * BDL : Bundle
	 * BIN : Bin
	 * BOX : Box
	 * BSK : Basket/Hampr
	 * BTL : Bottle
	 * BXT : Bucket
	 * CAB : Cabinet
	 * CAG : Cage
	 * CAN : Can
	 * CAS : Case
	 * CBY : Carby/Gaylrd
	 * CHS : Chest
	 * CNT : Container
	 * COL : Coil
	 * CRT : Crate
	 * CYL : Cylinder
	 * DRM : Drum
	 * ENV : Envelope
	 * FRM : Frame
	 * IBC : Int Bulk Ctr
	 * JAR : Jar
	 * JER : Jerrycan
	 * JUG : Jug
	 * KEG : Keg
	 * KIT : Kit
	 * LSE : Loose
	 * MSC : Miscellaneous
	 * PAL : Pail
	 * PKG : Package
	 * PLF : Platform
	 * RCK : Rack
	 * REL : Reel
	 * ROL : Roll
	 * SAK : Sack
	 * SLV : Sleeve
	 * SPL : Spool
	 * TBE : Tube
	 * TBN : Tote bin
	 * TNK : Tank
	 * TRY : Tray
	 * TUB : Tub
	 * Required: false
	 * Array: false
	 */
	packageType:string = undefined;
	/**
	 * nmfc item code
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * nmfc class : 500, 400, 300, 250, 200, 175, 150, 125, 110, 100, 92.5. 85, 77.5, 70, 65, 60, 55, 50
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
}
/**
 * Information about additional value added services requested
 */
export class BolAdditionalService {
	/**
	 * Service Accessorial Code:
	 * DCF : Container Station Delivery
	 * DID: Inside Delivery
	 * DLG: Liftgate Delivery
	 * DNC: Destination Notification
	 * DSH: Saturday or Holiday Delivery
	 * DST: Storage at Destination
	 * GUR: XPO Logistics Guaranteed
	 * OCF: Container Station Pickup
	 * OIP: Inside Pickup
	 * OLG: Origin Liftgate
	 * PFF: Protect from Freezing
	 * RSD: Residential Delivery
	 * RSO: Residential Pickup
	 * Required: false
	 * Array: false
	 */
	accsrlCode:string = undefined;
	/**
	 * valid values are: P or C. If not supplied, then default to BOL Request chargeToCd
	 * Required: false
	 * Array: false
	 */
	prepaidOrCollect:string = undefined;
}
/**
 * Basic BOL information required from external customers to create a Bill of Lading.Bill of lading is a required document to move a freight shipment. The bill of lading (BOL) works as a receipt of freight services, a contract between a freight carrier and shipper and a document of title. The bill of lading is a legally binding document providing the driver and the carrier all the details needed to process the freight shipment and invoice it correctly. 
 */
export class BolBasic {
	/**
	 * any known accessorial charges.
	 * Required: false
	 * Array: true
	 */
	additionalService:BolAdditionalService[] = undefined;
	/**
	 * Only one bill-to is supplied on the BOL. Until the BOL is bill-entered and the customers matched it is unknown whether the bill-to is associated with shipper or consignee.
	 * Required: false
	 * Array: false
	 */
	billToCust:PartyContactBasic = undefined;
	/**
	 * Original document date
	 * Required: false
	 * Array: false
	 */
	bolDateTime:Date = undefined;
	/**
	 * Hybrid field containing - BOL create Timestamp, Consignee Name, and Unique Reference ID.ï¿½
	 * Required: false
	 * Array: false
	 */
	bolDocNm:string = undefined;
	/**
	 * This is the version of BOL. Version 1 for created and incremented for each update.
	 * Required: false
	 * Array: false
	 */
	bolDocVer:string = undefined;
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Charge to code:
	 * Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. 
	 * Valid Values :
	 * 'P ' - Prepaid - shipper responsible for all charges
	 * 'C ' - Collect - consignee responsible for all charges
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * COD Remittance Information
	 * Required: false
	 * Array: false
	 */
	cod:CodRemit = undefined;
	/**
	 * commodity information for this bol including any hazmat infor
	 * Required: false
	 * Array: true
	 */
	commodityLine:CommodityInfo[] = undefined;
	/**
	 * Consignee Customer Address Information
	 * Required: false
	 * Array: false
	 */
	consignee:PartyContactBasic = undefined;
	/**
	 * Customs Broker Name and Address for International Shipments
	 * Required: false
	 * Array: false
	 */
	customsBroker:CustomsBroker = undefined;
	/**
	 * The liability of a shipment is based on the NMFC class of the shipment, but a US shipper can choose to request excess liability coverage from the carrier, for which they pay an extra charge. This field contains the shipment's total declared value as per the shipper.
	 * Required: false
	 * Array: false
	 */
	declaredValueAmt:MonetaryAmount = undefined;
	/**
	 * For Canadian Shippers only
	 * Required: false
	 * Array: false
	 */
	declaredValueAmtPerLb:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emergencyContactName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emergencyContactPhone:Phone = undefined;
	/**
	 * If a Canadian shipper chooses to pay excess liability charges for a shipment, the shipper must provide their initials designating their agreement.
	 * Required: false
	 * Array: false
	 */
	excessLiabilityChargeInit:string = undefined;
	/**
	 * If Pickup Request should also be created then supply the basic Pickup Request information
	 * Required: false
	 * Array: false
	 */
	pickupInfo:BolPickupBasic = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proTypeCd:string = undefined;
	/**
	 * Pro Number. This number can be supplied by the Shipper if they have XPO LTL supplied Pro Numbers. When input this Pro Number will be used. If not supplied and autoAssignPro is set to True then system will generate one and assign after the BoL is submitted. If not supplied and autoAssignPro is set to false then DSR will supply one.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * BOL Remarks
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * BOL Requester Information
	 * Required: false
	 * Array: false
	 */
	requester:BolRequester = undefined;
	/**
	 * Indicates if the email should be sent once the BOL is created. If true then email will be sent to Requester email address
	 * Required: false
	 * Array: false
	 */
	sendBolEmail:boolean = undefined;
	/**
	 * Shipper Party
	 * Address information
	 * Required: false
	 * Array: false
	 */
	shipper:PartyContactBasic = undefined;
	/**
	 * Additional customer supplemental references included on the BOL
	 * Required: false
	 * Array: false
	 */
	suppRef:BolReference = undefined;
}
/**
 * Reference type information used when capturing Bill of Lading information
 */
export class BolReference {
	/**
	 * Purchase Order Number
	 * Required: false
	 * Array: true
	 */
	purchaseOrderNbr:string[] = undefined;
	/**
	 * Shippers Reference Number
	 * Required: false
	 * Array: false
	 */
	shippersRefNbr:string = undefined;
	/**
	 * Other Reference Number to be captured when creating the Bill of Lading.Reference type and reference is required
	 * Required: false
	 * Array: true
	 */
	otherRefs:BolReference_ReferenceNumber[] = undefined;
}
/**
 * Basic shipment data required for a Bill of Lading
 */
export class BolShipmentBasic {
	/**
	 * Pro Number Text
	 * 
	 * PRO_NBR_TXT
	 * 	
	 * Desc: Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Charge to code
	 * 
	 * CHRG_TO_CD
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values :
	 * 'P ' - Prepaid - shipper responsible for all charges
	 * 'C ' - Collect - consignee responsible for all charges
	 * 'B' - Both - shipper and consignee responsible
	 * ' ' - Valid only for Non-Revenue bills.
	 * 
	 * Source: SHM_SHIPMENT.CHRG_TO_CD
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * Shipper Customer
	 * Address information
	 * Required: false
	 * Array: false
	 */
	shipper:ShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperContact:ContactInfo = undefined;
	/**
	 * Consignee Customer Address Information
	 * Required: false
	 * Array: false
	 */
	consignee:ShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeContact:ContactInfo = undefined;
	/**
	 * Only one bill-to is supplied on the BOL. Until the BOL is bill-entered and the customers matched it is unknown whether the bill-to is associated with shipper or consignee.
	 * Required: false
	 * Array: false
	 */
	bil2Cust:ShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bil2CustContact:ContactInfo = undefined;
	/**
	 * Total pieces count
	 * 
	 * TOT_PCS_CNT
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system.
	 * 
	 * Source : SHM_SHIPMENT.TOT_PCS_CNT
	 * Required: false
	 * Array: false
	 */
	totPiecesCnt:number = undefined;
	/**
	 * Count of non Motor Move pieces associated with a      
	 * shipment. This count plus Motor Moves defines the     
	 * total number of pieces moving within the ltl.xpo  system for Operations productivity.  This does notnecessarily balance with the total pieces or commodity
	 * pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCnt:number = undefined;
	/**
	 * Total number of pallets for the shipment
	 * Required: false
	 * Array: false
	 */
	palletCnt:number = undefined;
	/**
	 * Total weight (in pounds)
	 * 
	 * TOT_WGT_LBS
	 * 
	 * Desc : Total weight of all the freight being moved as a unit. If this is a 'Mother' bill then this is the total of all of the 'Daughter' bills.
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system.
	 * 
	 * Source : SHM_SHIPMENT.TOT_WGT_LBS
	 * Required: false
	 * Array: false
	 */
	totWeight:Weight = undefined;
	/**
	 * Hazardous Materials indicator
	 * 
	 * HAZMAT_IND
	 * 
	 * Desc : Indicates whether or not a shipment includes any commodities with hazardous materials.
	 * 
	 * Valid Values : Y/N
	 * 
	 * Source : SHM_SHIPMENT.HAZMAT_IND
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Additional customer supplemental references included on the BOL
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * commodity information for this bol including any hazmat infor
	 * Required: false
	 * Array: true
	 */
	commodityLine:CommodityLine[] = undefined;
	/**
	 * any known accessorial charges.
	 * Required: false
	 * Array: true
	 */
	accessorials:Accessorial[] = undefined;
}
/**
 * Basic shipment data required for a Bill of Lading
 */
export class BolShipment {
	/**
	 * Pro Number Text
	 * 
	 * PRO_NBR_TXT
	 * 	
	 * Desc: Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Pickup Date
	 * 
	 * PKUP_DT
	 * 
	 * Desc : The date that the Shipment was picked up from the customer.
	 * 
	 * Source : E-commerce; SHM_SHIPMENT.PKUP_DT
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * Charge to code
	 * 
	 * CHRG_TO_CD
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values :
	 * 'P ' - Prepaid - shipper responsible for all charges
	 * 'C ' - Collect - consignee responsible for all charges
	 * 'B' - Both - shipper and consignee responsible
	 * ' ' - Valid only for Non-Revenue bills.
	 * 
	 * Source: SHM_SHIPMENT.CHRG_TO_CD
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * Original terminal SIC
	 * 
	 * ORIG_TRMNL_SIC_CD
	 * 
	 * Desc : SIC where the Shipment originates.
	 * Required: false
	 * Array: false
	 */
	origTrmnlSIC:string = undefined;
	/**
	 * Destination terminal SIC
	 * 
	 * DEST_TRMNL_SIC_CD
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment.
	 * Required: false
	 * Array: false
	 */
	destTrmnlSIC:string = undefined;
	/**
	 * Occurred TimeStamp
	 * 
	 * OCCURRED_TMST	
	 * 
	 * Desc: This is the timestamp when the event occurred.
	 * Required: false
	 * Array: false
	 */
	occrdTmst:Date = undefined;
	/**
	 * Shipper Customer
	 * Address information
	 * Required: false
	 * Array: false
	 */
	shipper:BolShipment_ShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperContact:ContactInfo = undefined;
	/**
	 * Consignee Customer Address Information
	 * Required: false
	 * Array: false
	 */
	consignee:BolShipment_ShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeContact:ContactInfo = undefined;
	/**
	 * Only one bill-to is supplied on the BOL. Until the BOL is bill-entered and the customers matched it is unknown whether the bill-to is associated with shipper or consignee.
	 * Required: false
	 * Array: false
	 */
	bil2Cust:ShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bil2CustContact:ContactInfo = undefined;
	/**
	 * Total pieces count
	 * 
	 * TOT_PCS_CNT
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system.
	 * 
	 * Source : SHM_SHIPMENT.TOT_PCS_CNT
	 * Required: false
	 * Array: false
	 */
	totPiecesCnt:number = undefined;
	/**
	 * Count of non Motor Move pieces associated with a      
	 * shipment. This count plus Motor Moves defines the     
	 * total number of pieces moving within the ltl.xpo  system for Operations productivity.  This does notnecessarily balance with the total pieces or commodity
	 * pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCnt:number = undefined;
	/**
	 * number of motorized(forklift) moves requied to cross dock the shipment
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCnt:number = undefined;
	/**
	 * Total number of pallets for the shipment
	 * Required: false
	 * Array: false
	 */
	palletCnt:number = undefined;
	/**
	 * Total weight (in pounds)
	 * 
	 * TOT_WGT_LBS
	 * 
	 * Desc : Total weight of all the freight being moved as a unit. If this is a 'Mother' bill then this is the total of all of the 'Daughter' bills.
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system.
	 * 
	 * Source : SHM_SHIPMENT.TOT_WGT_LBS
	 * Required: false
	 * Array: false
	 */
	totWeight:Weight = undefined;
	/**
	 * Guaranteed Indicator
	 * 
	 * GARNTD_IND
	 * 
	 * Desc : Indicates if the shipment is under the ltl.xpo Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : E-commerce; SHM_SHIPMENT.GARNTD_IND
	 * Required: false
	 * Array: false
	 */
	garntInd:boolean = undefined;
	/**
	 * Hazardous Materials indicator
	 * 
	 * HAZMAT_IND
	 * 
	 * Desc : Indicates whether or not a shipment includes any commodities with hazardous materials.
	 * 
	 * Valid Values : Y/N
	 * 
	 * Source : SHM_SHIPMENT.HAZMAT_IND
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Additional customer supplemental references included on the BOL
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * commodity information for this bol including any hazmat infor
	 * Required: false
	 * Array: true
	 */
	commodityLine:CommodityLine[] = undefined;
	/**
	 * any known accessorial charges.
	 * Required: false
	 * Array: true
	 */
	accessorials:Charge[] = undefined;
}
/**
 * Bill of lading and Pickup Request Id information
 */
export class BolPickupReqId {
	/**
	 * Unique Identifier of the Bill of Lading
	 * Required: false
	 * Array: false
	 */
	bolInstId:string = undefined;
	/**
	 * Unique identifier of the Pickup Request
	 * Required: false
	 * Array: false
	 */
	pkupInstId:string = undefined;
	/**
	 * Service Center (SIC) responsible for Pickup
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSic:string = undefined;
	/**
	 * Date when the pickup request was called in
	 * Required: false
	 * Array: false
	 */
	pkupCallDate:Date = undefined;
	/**
	 * Call sequence number of the Pickup request
	 * Required: false
	 * Array: false
	 */
	pkupCallSeq:number = undefined;
	/**
	 * Pro Number Text
	 * 
	 * PRO_NBR_TXT
	 * 	
	 * Desc: Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Pickup confirmation number if a pickup request is specified
	 * Required: false
	 * Array: false
	 */
	pkupConfNbr:string = undefined;
}
/**
 * Pickup Request information for the Bill Of Lading
 */
export class BolPickup {
	/**
	 * Date of Pickup
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * Time of Pickup
	 * Required: false
	 * Array: false
	 */
	pkupTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSic:string = undefined;
	/**
	 * Time when the Dock closes at the Pickup location
	 * Required: false
	 * Array: false
	 */
	dockCloseTime:Date = undefined;
	/**
	 * Total Loose Pieces count
	 * Required: false
	 * Array: false
	 */
	totLoosePieceCnt:number = undefined;
	/**
	 * Total Pallets count
	 * Required: false
	 * Array: false
	 */
	totPalletCnt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totWeight:Weight = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	rqstdDeliveryDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	rqstdDeliveryTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totMotorizedPcCnt:number = undefined;
	/**
	 * Is Holiday Delivery Requested?
	 * Required: false
	 * Array: false
	 */
	holidayDeliveryInd:boolean = undefined;
}
/**
 * Pickup Request information for the Bill Of Lading
 */
export class BolPickupBasic {
	/**
	 * Date of Pickup
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * Time of Pickup
	 * Required: false
	 * Array: false
	 */
	pkupTime:Date = undefined;
	/**
	 * Time when the Dock closes at the Pickup location
	 * Required: false
	 * Array: false
	 */
	dockCloseTime:Date = undefined;
	/**
	 * Pickup contact information
	 * Required: false
	 * Array: false
	 */
	contact:ContactInfoBasic = undefined;
	/**
	 * Call sequence number of the Pickup request
	 * Required: false
	 * Array: false
	 */
	pkupCallSeq:number = undefined;
	/**
	 * Date when the pickup request was called in
	 * Required: false
	 * Array: false
	 */
	pkupCallDate:Date = undefined;
	/**
	 * Unique identifier of the Pickup Request
	 * Required: false
	 * Array: false
	 */
	pkupInstId:string = undefined;
	/**
	 * Service Center (SIC) responsible for Pickup
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSic:string = undefined;
	/**
	 * Number of pallets in the shipment
	 * Required: false
	 * Array: false
	 */
	palletCnt:number = undefined;
	/**
	 * Total Volume in Cubic Feet
	 * 
	 * TOT_VOL_CFT
	 * 
	 * Cubic feet of the Shipment. This may be either the sum of the measured dimensions, or the estimate calculated from the shipment characteristics using the customer profile of location default density.
	 * Required: false
	 * Array: false
	 */
	totVolumeCft:Volume = undefined;
	/**
	 * Freezable Indicator
	 * 
	 * FRZBLE_IND
	 * 
	 * Desc : Indicates whether shipment requires special
	 * handling to prevent freezing during harsh weather.
	 * 
	 * Valid Values : true/false
	 * 
	 * true - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	frzbleInd:boolean = undefined;
	/**
	 * Indicates whether holiday/weekend delivery has been requested by customer
	 * Required: false
	 * Array: false
	 */
	holDlvrInd:boolean = undefined;
	/**
	 * Total number of loose pieces for all the shipments in the pickup request
	 * Required: false
	 * Array: false
	 */
	totLoosePieceCnt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupShipDimensions:Dimensions = undefined;
}
/**
 * Information about the Persone requesting the Bill Of Lading
 */
export class BolRequester {
	/**
	 * Required: false
	 * Array: false
	 */
	requester:Person = undefined;
	/**
	 * Role of the BOL Requester - Shipper, Consignee or Bill2
	 * Required: false
	 * Array: false
	 */
	role:string = undefined;
	/**
	 * Web Customer profile Id
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Set to E for Electronic
	 * Required: false
	 * Array: false
	 */
	userTypeCd:string = undefined;
}
/**
 * Basic BOL information required from external customers to create a Bill of Lading.Bill of lading is a required document to move a freight shipment. The bill of lading (BOL) works as a receipt of freight services, a contract between a freight carrier and shipper and a document of title. The bill of lading is a legally binding document providing the driver and the carrier all the details needed to process the freight shipment and invoice it correctly. 
 */
export class zzzBolBasic {
	/**
	 * Original document date
	 * Required: false
	 * Array: false
	 */
	bolDateTime:Date = undefined;
	/**
	 * Hybrid field containing - BOL create Timestamp, Consignee Name, and Unique Reference ID.ï¿½
	 * Required: false
	 * Array: false
	 */
	bolDocNm:string = undefined;
	/**
	 * This is the version of BOL. Version 1 for created and incremented for each update.
	 * Required: false
	 * Array: false
	 */
	bolDocVer:string = undefined;
	/**
	 * Shipment and commodity information provided in this bill of lading
	 * Required: false
	 * Array: false
	 */
	bolShipment:BolShipmentBasic = undefined;
	/**
	 * any comments included with the BOL
	 * Required: false
	 * Array: true
	 */
	bolRemarks:Comment[] = undefined;
	/**
	 * Information about the Customs Broker
	 * Required: false
	 * Array: false
	 */
	customsBroker:CustomsBroker = undefined;
	/**
	 * COD Remittance Information
	 * Required: false
	 * Array: false
	 */
	cod:CodRemit = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requester:BolRequester = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * Bill of lading is a required document to move a freight shipment. The bill of lading (BOL) works as a receipt of freight services, a contract between a freight carrier and shipper and a document of title. The bill of lading is a legally binding document providing the driver and the carrier all the details needed to process the freight shipment and invoice it correctly. 
 */
export class Bol {
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstID:string = undefined;
	/**
	 * This indicates the type of BOL. 
	 *    BL=Bill of Lading,     LT=Load Tender
	 *    CO=Consolidation,   ZZ=Mutually Defined
	 *    PC=Partial Load Tender, Carrier consolidate
	 *    PS=Partial Load Tender, Shipper consolidate
	 * Required: false
	 * Array: false
	 */
	bolTypeCd:string = undefined;
	/**
	 * Create Timestamp
	 * 
	 * CREATE_TMST
	 * 
	 * 
	 * Desc: Attribute identifying when the BOL record was created.
	 * Required: false
	 * Array: false
	 */
	evtCreateTmst:Date = undefined;
	/**
	 * Event Type Code
	 * 
	 * Mapped from
	 * 
	 * SUB_TYPE_CD
	 * 
	 * Desc : Unique BOL events
	 * 
	 * Values:
	 * 1C Web Bill of Lading Update
	 * 1D EDI Bill of Lading Update
	 * 1E WEB Bill of Lading Cancel
	 * 1F EDI Bill of Lading Cancel
	 * 1G WEB Bill of Lading SR Update
	 * 1H EDI Bill of Lading SR Update
	 * 1J BOL Print
	 * Required: false
	 * Array: false
	 */
	bolEvtTypeCd:string = undefined;
	/**
	 * This represents how the incoming customer message will be interpreted. It might be:
	 * 
	 * PickupOnly
	 * BOLOnly
	 * PickupBOL
	 * BOLPrintOnly
	 * BOLPrintBOL
	 * BOLPrintBOLPickup
	 * SRNbrOnly
	 * BOLPrintPickup
	 * Required: false
	 * Array: false
	 */
	messageTypeCd:string = undefined;
	/**
	 * Identifies whether the BOL information sent from the customer is still to be used (i.e. Active). If the customer has indicated that this BOL request has been cancelled we will retain the information but will indicate that it should no longer be used (i.e. This comes through as a Cancel in the EDI data)
	 * 
	 * Values:
	 * Active
	 * ancelled
	 * Required: false
	 * Array: false
	 */
	bolStatusCd:string = undefined;
	/**
	 * Original document date
	 * Required: false
	 * Array: false
	 */
	bolDateTime:Date = undefined;
	/**
	 * Identifies the source of BOL creation: EDI or XML or API or online application
	 * Required: false
	 * Array: false
	 */
	bolSource:string = undefined;
	/**
	 * Identifies where the template was originally created.
	 * 
	 * Values:
	 * Upload
	 * Copied from another user
	 * Online
	 * AutoBOL
	 * Required: false
	 * Array: false
	 */
	templateSrcCd:string = undefined;
	/**
	 * An eBOL can be accepted even if there are errors in the data. This indicates if there were indeed errors in the data at time of acceptance
	 * Required: false
	 * Array: false
	 */
	acceptedWithExceptionInd:boolean = undefined;
	/**
	 * Hybrid field containing - BOL create Timestamp, Consignee Name, and Unique Reference ID.ï¿½
	 * Required: false
	 * Array: false
	 */
	bolDocNm:string = undefined;
	/**
	 * This is the version of BOL. Version 1 for created and incremented for each update.
	 * Required: false
	 * Array: false
	 */
	bolDocVer:string = undefined;
	/**
	 * Datetime for any update
	 * Required: false
	 * Array: false
	 */
	bolDocVerDateTime:Date = undefined;
	/**
	 * Indicator that allows for the system to be run in test mode. Currently only XML uses this flag.
	 * Required: false
	 * Array: false
	 */
	testModeInd:boolean = undefined;
	/**
	 * EDI Sender ID
	 * 
	 * EDI_SENDER_ID
	 * 
	 * Desc: This is the Sender-ID of the EDI Customer as supplied by EDI.
	 * Required: false
	 * Array: false
	 */
	ediSenderID:string = undefined;
	/**
	 * Sender's Shipment Identifier
	 * 
	 * EDI_SENDER_SHP_ID
	 * 
	 * Desc: This is the Shipment Identifier of the EDI eBOL as supplied by the EDI Customer.
	 * Required: false
	 * Array: false
	 */
	ediSenderShpID:string = undefined;
	/**
	 * Shipment Instance ID
	 * 
	 * SHP_INST_ID
	 * 
	 * Desc: This is the unique ltl.xpo Shipment Identifier of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstID:string = undefined;
	/**
	 * Pickup Request Instance ID
	 * 
	 * PUR_INST_ID
	 * 
	 * Desc: Instance ID of the Pickup Request that is asociated with this Shipment.
	 * Required: false
	 * Array: false
	 */
	pkupInstID:string = undefined;
	/**
	 * Shipment and commodity information provided in this bill of lading
	 * Required: false
	 * Array: false
	 */
	bolShipment:BolShipment = undefined;
	/**
	 * any comments included with the BOL
	 * Required: false
	 * Array: true
	 */
	bolRemarks:Comment[] = undefined;
	/**
	 * the date the pickup request was called in
	 * Required: false
	 * Array: false
	 */
	pkupCallDate:Date = undefined;
	/**
	 * A number automatically generated by the system for a specific call date. This is a sequential number and the value will start with 1 for a call date.
	 * Required: false
	 * Array: false
	 */
	pkupCallSeq:number = undefined;
	/**
	 * type of special equipment required for this pickup
	 * Required: false
	 * Array: false
	 */
	pkupSpecialEquipmentCd:string = undefined;
	/**
	 * Information about the person who requested the pick up
	 * Required: false
	 * Array: false
	 */
	pkupRequestor:Person = undefined;
	/**
	 * Contact person for the pickup request
	 * Required: false
	 * Array: false
	 */
	pkupContact:Bol_ContactInfo = undefined;
	/**
	 * Contact person at the time of Delivery
	 * Required: false
	 * Array: false
	 */
	deliveryContact:Bol_ContactInfo = undefined;
	/**
	 * Any pickup request timing information if provided.
	 * Required: false
	 * Array: false
	 */
	pkupDlvryTimeInfo:Tender = undefined;
	/**
	 * Additional Pickup Request details
	 * Required: false
	 * Array: false
	 */
	pkupAddlInfo:BolPickup = undefined;
	/**
	 * Equipment number for the trailer that is to be 
	 * collected at the time of pickup.
	 * Required: false
	 * Array: false
	 */
	pkupEquipmentInfo:Bol_EquipmentInfo = undefined;
	/**
	 * Information about the Customs Broker
	 * Required: false
	 * Array: false
	 */
	customsBroker:CustomsBroker = undefined;
	/**
	 * COD Remittance Information
	 * Required: false
	 * Array: false
	 */
	cod:CodRemit = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requester:BolRequester = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
export class EdiBolHeader {
	/**
	 * EDI Document type: 204, 211
	 * Required: false
	 * Array: false
	 */
	ediDocType:string = undefined;
	/**
	 * edi direction
	 * Required: false
	 * Array: false
	 */
	ediDocDirection:string = undefined;
	/**
	 * Receiver Id
	 * Required: false
	 * Array: false
	 */
	ediRecvrId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediBatchNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediTranSeqNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	allowUpdateCancelInd:boolean = undefined;
	/**
	 * logon sic for BOLprint
	 * Required: false
	 * Array: false
	 */
	userSicCd:string = undefined;
	/**
	 * logon inst id for BOLprint
	 * Required: false
	 * Array: false
	 */
	userInstId:string = undefined;
	/**
	 * logon user id for BOLprint
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Remittance Information for COD
 */
export class CodRemit {
	/**
	 * COD Fee payment type: Prepaid or Collect
	 * Required: false
	 * Array: false
	 */
	paymentTypeCd:string = undefined;
	/**
	 * Remit to Customer Information
	 * Required: false
	 * Array: false
	 */
	remit:PartyBasic = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	remitAmount:MonetaryAmount = undefined;
	/**
	 * Type of check to be accepted: Personal, Certified, Cashiers, Money Order
	 * Required: false
	 * Array: false
	 */
	acceptCheckType:string = undefined;
}
/**
 * Information about Customs Broker
 */
export class CustomsBroker {
	/**
	 * This is an internal identifier to the customs broker that this shipment party uses for a shipment that will cross a border.
	 * Required: false
	 * Array: false
	 */
	brokerInstId:string = undefined;
	/**
	 * This is a business key that identifies the customs broker that this shipment party uses for a shipment that will cross a border.
	 * Required: false
	 * Array: false
	 */
	brokerBusinessKey:string = undefined;
	/**
	 * Customs broker address
	 * Required: false
	 * Array: false
	 */
	address:Address = undefined;
	/**
	 * Customs broker contact information
	 * Required: false
	 * Array: false
	 */
	contactInfo:ContactInfoBasic = undefined;
}
export class Tender {
	/**
	 * Required: false
	 * Array: false
	 */
	tenderOfferDateTime:Date = undefined;
	/**
	 * Expiration of tender offer
	 * Required: false
	 * Array: false
	 */
	tenderExpDateTime:Date = undefined;
	/**
	 * Tender response deadline
	 * Required: false
	 * Array: false
	 */
	tenderRespDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	earliestReqPkupDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	latestReqPkupDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	earliestDlvryDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	latestDlvryDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tenderResponseCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tenderRejectionReasonCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tenderID:string = undefined;
}
export class BolAccessorial {
	/**
	 * Required: false
	 * Array: false
	 */
	accsrlcd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeCd:ChargeToCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sequenceNumber:number = undefined;
}
export class BolCashOnDelivery {
	/**
	 * Required: false
	 * Array: false
	 */
	codAmount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codChargeTypeCode:ChargeToCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codCompanyName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codCustomer:BolParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codRemitToName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codSequenceNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	paymentTypeCode:MiscLineItemPaymentMethodCd = undefined;
}
export class BolCommodity {
	/**
	 * Required: false
	 * Array: false
	 */
	classTxt:CommodityClassCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazamatInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	nmfcTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pakageTxt:CommodityPackageCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sequenceNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
}
export class BolDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	billTo:BolParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolCodCustomer:BolCashOnDelivery = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolDoc:BolDocument = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolInstanceId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolRequestor:BolRequestor = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolpickLst:BolPickList = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee:BolParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custProfInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatRemarkTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatRemarkTypeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper:BolParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shpRemarkTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shpRemarkTypeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPiecesCnt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
}
export class BolDocument {
	/**
	 * Required: false
	 * Array: false
	 */
	acceptedWithExceptionInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolStatusCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeCd:ChargeToCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cisCustIdentifier:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createDt:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custSuppliedProNbrTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deferredInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destTrmnlSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	documentName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	documentTypeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emrCntctName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emrCntctPhone:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	holidayInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUsedDt:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origTrmnlSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNumberTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proTypeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refNumberTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requiredDlvrDt:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shpInstanceId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shpmntRemarksTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourcecd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	templateSrcCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	testModeInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	versionNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	svcTypCd:string = undefined;
}
export class BolParty {
	/**
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addressLine2:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cisCustomerNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cisMadCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countryCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerName1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerName2:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	matchStatusCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pacdNumber:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	phonNumber:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	phonrextensionNumber:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zipCode:string = undefined;
}
export class BolPickList {
	/**
	 * Required: false
	 * Array: false
	 */
	consZip6:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeAddressTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destAreaCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destTrmnlSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	excludeInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origAreacd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origTrmnlSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupDt:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNumberTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reserveInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperCisCustNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperMadCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourcecd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbrtxt:string = undefined;
}
export class BolPickupRequest {
	/**
	 * Required: false
	 * Array: false
	 */
	areaCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	callDt:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	callSeqNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	contactCmpnyTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	contactPhntxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	dockCloseTme:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupDt:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupTme:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sequenceNumber:number = undefined;
}
export class BolRequestor {
	/**
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	declPerLb:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	declTot:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ediSenderShpId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	exLiaInit:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pccdPhone:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requestorAreaCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requestorCompanyName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requestorEmail:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requestorName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	requestorPhone:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	role:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	userIdNumberTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	userTypeCd:string = undefined;
}
export class BolSupplementalRef {
	/**
	 * Required: false
	 * Array: false
	 */
	refCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refDescripton:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refNumberTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refSequenceNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
export class CreateBillOfLadingTemplateResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class UpdateBillOfLadingTemplateResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class ListBillOfLadingTemplatesResp_BolBasic extends BolBasic {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class ListSubmittedBillsOfLadingResp_BolBasic extends BolBasic {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class CreateBillOfLadingResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class CreateOcrBillOfLadingResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class UpdateBillOfLadingResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class UploadBillOfLadingTemplatesResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class CopyBillOfLadingTemplatesResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Required: false
	 * Array: false
	 */
	templateName:string = undefined;
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class CreateBillOfLadingDraftResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class UpdateBillOfLadingDraftResp_BolPickupReqId extends BolPickupReqId {
	/**
	 * Hypermedia links
	 * Required: false
	 * Array: true
	 */
	link:HyperMedia[] = undefined;
}
export class BolReference_ReferenceNumber extends ReferenceNumber {
	/**
	 * Required: false
	 * Array: false
	 */
	referenceCode:string = undefined;
}
export class BolShipment_ShipmentParty extends ShipmentParty {
	/**
	 * P(Pickup and Delivery), F(Future Customer), B(BillTo)
	 * Required: false
	 * Array: false
	 */
	customerType:string = undefined;
}
export class Bol_ContactInfo extends ContactInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	fullName:string = undefined;
}
export class Bol_EquipmentInfo extends EquipmentInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
}

/**
 * Query parameters for getBolDetails
 */
export class GetBolDetailsQuery {
	bolInstId: string = undefined;
	proNbr: string = undefined;
	customerSuppliedProNbr: string = undefined;
}

/**
 * Query parameters for createEdiBillOfLadingPickupRequest
 */
export class CreateEdiBillOfLadingPickupRequestQuery {
	testMode: string = undefined;
	autoAssignPro: string = undefined;
}

/**
 * Query parameters for listSubmittedBillsOfLading
 */
export class ListSubmittedBillsOfLadingQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getBillOfLading
 */
export class GetBillOfLadingQuery {
	requesterId: string = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for createBillOfLading
 */
export class CreateBillOfLadingQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for updateBillOfLading
 */
export class UpdateBillOfLadingQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for cancelBillOfLading
 */
export class CancelBillOfLadingQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for getBillOfLadingPdf
 */
export class GetBillOfLadingPdfQuery {
	requesterId: string = undefined;
	requesterToSign: string = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for emailBillOfLadingPdf
 */
export class EmailBillOfLadingPdfQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for listBillOfLading
 */
export class ListBillOfLadingQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
	listType: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for generateBillOfLadingPdf
 */
export class GenerateBillOfLadingPdfQuery {
	bolInstId: string[] = undefined;
	requesterId: string = undefined;
	requesterToSign: string = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for generateBillOfLadingCsv
 */
export class GenerateBillOfLadingCsvQuery {
	requesterId: string = undefined;
	bolInstId: string[] = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for getBillOfLadingTemplate
 */
export class GetBillOfLadingTemplateQuery {
	requesterId: string = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for listBillOfLadingTemplates
 */
export class ListBillOfLadingTemplatesQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for createBillOfLadingTemplate
 */
export class CreateBillOfLadingTemplateQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for updateBillOfLadingTemplate
 */
export class UpdateBillOfLadingTemplateQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for deleteBillOfLadingTemplate
 */
export class DeleteBillOfLadingTemplateQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for uploadBillOfLadingTemplates
 */
export class UploadBillOfLadingTemplatesQuery {
	testMode: string = undefined;
	validateRequest: string = undefined;
	requesterId: string = undefined;
}

/**
 * Query parameters for getBillOfLadingDraft
 */
export class GetBillOfLadingDraftQuery {
	requesterId: string = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for createBillOfLadingDraft
 */
export class CreateBillOfLadingDraftQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for updateBillOfLadingDraft
 */
export class UpdateBillOfLadingDraftQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for deleteBillOfLadingDraft
 */
export class DeleteBillOfLadingDraftQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for listBOLCommodityPreferences
 */
export class ListBOLCommodityPreferencesQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
}

/**
 * Query parameters for createBOLCommodityPreference
 */
export class CreateBOLCommodityPreferenceQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
}

/**
 * Query parameters for updateBOLCommodityPreference
 */
export class UpdateBOLCommodityPreferenceQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
}

/**
 * Query parameters for deleteBOLCommodityPreference
 */
export class DeleteBOLCommodityPreferenceQuery {
	testMode: string = undefined;
	requesterId: string = undefined;
}

/**
 * Query parameters for uploadBOLCommodityPreferences
 */
export class UploadBOLCommodityPreferencesQuery {
	testMode: string = undefined;
	validateRequest: string = undefined;
	requesterId: string = undefined;
}

/**
 * Query parameters for createPapsLabelPdf
 */
export class CreatePapsLabelPdfQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for createShippingLabelPdf
 */
export class CreateShippingLabelPdfQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for emailPapsLabelPdf
 */
export class EmailPapsLabelPdfQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for emailShippingLabelPdf
 */
export class EmailShippingLabelPdfQuery {
	testMode: string = undefined;
}

/**
 * Query parameters for generateShippingLabelPdf
 */
export class GenerateShippingLabelPdfQuery {
	bolInstId: string[] = undefined;
	nbrOfLabels: number = undefined;
	labelPosition: number = undefined;
	printerSettings: string = undefined;
	testMode: string = undefined;
}

/**
 * Query parameters for generatePapsLabelPdf
 */
export class GeneratePapsLabelPdfQuery {
	bolInstId: string[] = undefined;
	nbrOfLabels: number = undefined;
	labelPosition: number = undefined;
	testMode: string = undefined;
}


/**
 * Path parameters for getSuppRefNumbers
 */
export class GetSuppRefNumbersPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for getBillOfLading
 */
export class GetBillOfLadingPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for updateBillOfLading
 */
export class UpdateBillOfLadingPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for cancelBillOfLading
 */
export class CancelBillOfLadingPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for getBillOfLadingPdf
 */
export class GetBillOfLadingPdfPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for emailBillOfLadingPdf
 */
export class EmailBillOfLadingPdfPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for getBillOfLadingTemplate
 */
export class GetBillOfLadingTemplatePath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for updateBillOfLadingTemplate
 */
export class UpdateBillOfLadingTemplatePath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for deleteBillOfLadingTemplate
 */
export class DeleteBillOfLadingTemplatePath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for getBillOfLadingDraft
 */
export class GetBillOfLadingDraftPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for updateBillOfLadingDraft
 */
export class UpdateBillOfLadingDraftPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for deleteBillOfLadingDraft
 */
export class DeleteBillOfLadingDraftPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for updateBOLCommodityPreference
 */
export class UpdateBOLCommodityPreferencePath {
	sequenceId: string = undefined;
}

/**
 * Path parameters for deleteBOLCommodityPreference
 */
export class DeleteBOLCommodityPreferencePath {
	sequenceId: string = undefined;
}

/**
 * Path parameters for createPapsLabelPdf
 */
export class CreatePapsLabelPdfPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for createShippingLabelPdf
 */
export class CreateShippingLabelPdfPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for emailPapsLabelPdf
 */
export class EmailPapsLabelPdfPath {
	bolInstId: string = undefined;
}

/**
 * Path parameters for emailShippingLabelPdf
 */
export class EmailShippingLabelPdfPath {
	bolInstId: string = undefined;
}

