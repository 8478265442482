import {ShipperLoadCountResolutionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipperLoadCountResolutionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipperLoadCountResolutionTypeCd ) {
      this._values.push(ShipperLoadCountResolutionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipperLoadCountResolutionTypeCd {

    for ( const obj in ShipperLoadCountResolutionTypeCd ) {
      if (ShipperLoadCountResolutionTypeCd[obj] === value){
        return ShipperLoadCountResolutionTypeCd[obj] as ShipperLoadCountResolutionTypeCd;
      }
    }
  }
}

const ShipperLoadCountResolutionTypeCdHelper = new ShipperLoadCountResolutionTypeCdHelperEnumHelperClass();
export default ShipperLoadCountResolutionTypeCdHelper;
