import {LocationAddressTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LocationAddressTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LocationAddressTypeCd ) {
      this._values.push(LocationAddressTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LocationAddressTypeCd {

    for ( const obj in LocationAddressTypeCd ) {
      if (LocationAddressTypeCd[obj] === value){
        return LocationAddressTypeCd[obj] as LocationAddressTypeCd;
      }
    }
  }
}

const LocationAddressTypeCdHelper = new LocationAddressTypeCdHelperEnumHelperClass();
export default LocationAddressTypeCdHelper;
