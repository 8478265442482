import {ShortChecklistApprovalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShortChecklistApprovalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShortChecklistApprovalStatusCd ) {
      this._values.push(ShortChecklistApprovalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShortChecklistApprovalStatusCd {

    for ( const obj in ShortChecklistApprovalStatusCd ) {
      if (ShortChecklistApprovalStatusCd[obj] === value){
        return ShortChecklistApprovalStatusCd[obj] as ShortChecklistApprovalStatusCd;
      }
    }
  }
}

const ShortChecklistApprovalStatusCdHelper = new ShortChecklistApprovalStatusCdHelperEnumHelperClass();
export default ShortChecklistApprovalStatusCdHelper;
