import {LocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LocationTypeCd ) {
      this._values.push(LocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LocationTypeCd {

    for ( const obj in LocationTypeCd ) {
      if (LocationTypeCd[obj] === value){
        return LocationTypeCd[obj] as LocationTypeCd;
      }
    }
  }
}

const LocationTypeCdHelper = new LocationTypeCdHelperEnumHelperClass();
export default LocationTypeCdHelper;
