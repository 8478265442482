import {IntermodalSatelliteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class IntermodalSatelliteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in IntermodalSatelliteTypeCd ) {
      this._values.push(IntermodalSatelliteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): IntermodalSatelliteTypeCd {

    for ( const obj in IntermodalSatelliteTypeCd ) {
      if (IntermodalSatelliteTypeCd[obj] === value){
        return IntermodalSatelliteTypeCd[obj] as IntermodalSatelliteTypeCd;
      }
    }
  }
}

const IntermodalSatelliteTypeCdHelper = new IntermodalSatelliteTypeCdHelperEnumHelperClass();
export default IntermodalSatelliteTypeCdHelper;
