import {LocationPhoneTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LocationPhoneTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LocationPhoneTypeCd ) {
      this._values.push(LocationPhoneTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LocationPhoneTypeCd {

    for ( const obj in LocationPhoneTypeCd ) {
      if (LocationPhoneTypeCd[obj] === value){
        return LocationPhoneTypeCd[obj] as LocationPhoneTypeCd;
      }
    }
  }
}

const LocationPhoneTypeCdHelper = new LocationPhoneTypeCdHelperEnumHelperClass();
export default LocationPhoneTypeCdHelper;
