
export enum CorrectionReasonCd {
	CORR_1001 = 'Corr1001',
	CORR_1002 = 'Corr1002',
	CORR_1004 = 'Corr1004',
	CORR_1008 = 'Corr1008',
	CORR_1102 = 'Corr1102',
	CORR_1103 = 'Corr1103',
	CORR_1107 = 'Corr1107',
	CORR_1112 = 'Corr1112',
	CORR_1117 = 'Corr1117',
	CORR_1118 = 'Corr1118',
	CORR_120 = 'Corr120',
	CORR_2002 = 'Corr2002',
	CORR_2003 = 'Corr2003',
	CORR_3001 = 'Corr3001',
	CORR_3002 = 'Corr3002',
	CORR_3003 = 'Corr3003',
	CORR_3004 = 'Corr3004',
	CORR_3005 = 'Corr3005',
	CORR_4003 = 'Corr4003',
	CORR_4004 = 'Corr4004',
	CORR_4005 = 'Corr4005',
	CORR_4008 = 'Corr4008',
	CORR_4012 = 'Corr4012',
	CORR_4013 = 'Corr4013',
	CORR_4014 = 'Corr4014',
	CORR_4015 = 'Corr4015',
	CORR_4016 = 'Corr4016',
	CORR_4017 = 'Corr4017',
	CORR_5002 = 'Corr5002',
	CORR_5003 = 'Corr5003',
	CORR_5004 = 'Corr5004',
	CORR_5005 = 'Corr5005',
	CORR_7001 = 'Corr7001',
	CORR_7002 = 'Corr7002',
	CORR_7003 = 'Corr7003',
	CORR_7004 = 'Corr7004',
	CORR_76 = 'Corr76',
	CORR_8002 = 'Corr8002',
	CORR_8003 = 'Corr8003',
	CORR_8004 = 'Corr8004',
	CORR_8005 = 'Corr8005',
	CORR_9001 = 'Corr9001',
	CORR_9002 = 'Corr9002',
	CORR_91 = 'Corr91',
	CORR_9101 = 'Corr9101',
	CORR_9102 = 'Corr9102',
	CORR_94 = 'Corr94',
	CORR_95 = 'Corr95',
	CORR_9501 = 'Corr9501',
	CORR_96 = 'Corr96',
	CORR_97 = 'Corr97',
	CORR_98 = 'Corr98'}
