import {ScheduleFrequencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class ScheduleFrequencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ScheduleFrequencyCd ) {
      this._values.push(ScheduleFrequencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ScheduleFrequencyCd {

    for ( const obj in ScheduleFrequencyCd ) {
      if (ScheduleFrequencyCd[obj] === value){
        return ScheduleFrequencyCd[obj] as ScheduleFrequencyCd;
      }
    }
  }
}

const ScheduleFrequencyCdHelper = new ScheduleFrequencyCdHelperEnumHelperClass();
export default ScheduleFrequencyCdHelper;
