import {RerateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RerateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RerateTypeCd ) {
      this._values.push(RerateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RerateTypeCd {

    for ( const obj in RerateTypeCd ) {
      if (RerateTypeCd[obj] === value){
        return RerateTypeCd[obj] as RerateTypeCd;
      }
    }
  }
}

const RerateTypeCdHelper = new RerateTypeCdHelperEnumHelperClass();
export default RerateTypeCdHelper;
