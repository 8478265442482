
export enum ServiceLevelCd {
	APPT_STORAGE = 'ApptStorage',
	BRONZE = 'Bronze',
	CUST_DIGEST = 'CustDigest',
	GOLD = 'Gold',
	PRIORITY_PICKUP = 'PriorityPickup',
	SILVER = 'Silver',
	WHITE_GLOVE = 'WhiteGlove'}

