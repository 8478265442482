import {OsdPayloadTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class OsdPayloadTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OsdPayloadTypeCd ) {
      this._values.push(OsdPayloadTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OsdPayloadTypeCd {

    for ( const obj in OsdPayloadTypeCd ) {
      if (OsdPayloadTypeCd[obj] === value){
        return OsdPayloadTypeCd[obj] as OsdPayloadTypeCd;
      }
    }
  }
}

const OsdPayloadTypeCdHelper = new OsdPayloadTypeCdHelperEnumHelperClass();
export default OsdPayloadTypeCdHelper;
