import {CompanyOperationNameCd} from '../';
import {EnumHelper} from './enum-helper';

export class CompanyOperationNameCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CompanyOperationNameCd ) {
      this._values.push(CompanyOperationNameCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CompanyOperationNameCd {

    for ( const obj in CompanyOperationNameCd ) {
      if (CompanyOperationNameCd[obj] === value){
        return CompanyOperationNameCd[obj] as CompanyOperationNameCd;
      }
    }
  }
}

const CompanyOperationNameCdHelper = new CompanyOperationNameCdHelperEnumHelperClass();
export default CompanyOperationNameCdHelper;
