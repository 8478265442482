import {CloseReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class CloseReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CloseReasonCd ) {
      this._values.push(CloseReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CloseReasonCd {

    for ( const obj in CloseReasonCd ) {
      if (CloseReasonCd[obj] === value){
        return CloseReasonCd[obj] as CloseReasonCd;
      }
    }
  }
}

const CloseReasonCdHelper = new CloseReasonCdHelperEnumHelperClass();
export default CloseReasonCdHelper;
