import {CrcStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CrcStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CrcStatusCd ) {
      this._values.push(CrcStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CrcStatusCd {

    for ( const obj in CrcStatusCd ) {
      if (CrcStatusCd[obj] === value){
        return CrcStatusCd[obj] as CrcStatusCd;
      }
    }
  }
}

const CrcStatusCdHelper = new CrcStatusCdHelperEnumHelperClass();
export default CrcStatusCdHelper;
