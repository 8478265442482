import {ShortChecklistStepCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShortChecklistStepCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShortChecklistStepCd ) {
      this._values.push(ShortChecklistStepCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShortChecklistStepCd {

    for ( const obj in ShortChecklistStepCd ) {
      if (ShortChecklistStepCd[obj] === value){
        return ShortChecklistStepCd[obj] as ShortChecklistStepCd;
      }
    }
  }
}

const ShortChecklistStepCdHelper = new ShortChecklistStepCdHelperEnumHelperClass();
export default ShortChecklistStepCdHelper;
